import Vue from 'vue'
import AuditEntry from '@/utils/audit/AuditEntry'

export default {
  getTreatment(patientNumber: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/prescription/session/treatments', {
          params: { patientNumber },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('treatment error', error, {
            patientNumber,
          })
          reject(error)
        })
    })
  },
  isNewPrescriptionAllowed(
    patientNumber: string,
    startedAt,
    prescriptionConfigurationId,
    type
  ) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/prescription/session/allowed', {
          patientNumber,
          startedAt,
          prescriptionConfigurationId,
          type,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('newPrescriptionAllowed error', error, {
            patientNumber,
            startedAt,
          })
          reject(error)
        })
    })
  },
  startNewPrescription(
    patientNumber: string,
    startedAt,
    prescriptionConfigurationId,
    type
  ) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/prescription/session', {
          patientNumber,
          startedAt,
          prescriptionConfigurationId,
          type,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('startNewPrescription error', error, {
            patientNumber,
            startedAt,
          })
          reject(error)
        })
    })
  },
  deriveDose(
    patientNumber: string,
    siteNumber: string,
    treatmentArmId: string,
    doseValue: number
  ) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/prescription/session/derive', {
          patientNumber,
          siteNumber,
          treatmentArmId,
          doseValue,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('deriveDose error', error, {
            patientNumber,
            siteNumber,
            treatmentArmId,
            doseValue,
          })
          reject(error)
        })
    })
  },
  getDoubleDummyTitrationTable(siteNumber: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/prescription/session/insulintranslation', {
          params: { siteNumber },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('deriveDose error', error, {
            siteNumber,
          })
          reject(error)
        })
    })
  },
  setPrescription(
    patientNumber: string,
    sessionId: string,
    setAt,
    doses,
    bloodGlucoseUnit?
  ) {
    const requestData = {
      patientNumber,
      sessionId,
      setAt,
      doses,
      bloodGlucoseUnit,
    }
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/prescription/session', requestData)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('setPrescription error', error, {
            ...requestData,
          })
          reject(error)
        })
    })
  },
  previewPrescriptions(patientNumber: string, sessionId: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/prescription/session', {
          params: { patientNumber, sessionId },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('previewPrescriptions error', error, {
            patientNumber,
            sessionId,
          })
          reject(error)
        })
    })
  },

  checkDoseValue(patientNumber: string, sessionId: string, dose) {
    return this.checkPrescription(patientNumber, sessionId, [
      { timepoint: dose.timepoint, doseValue: dose.value },
    ])
  },

  checkPrescription(patientNumber: string, sessionId: string, doses: any[]) {
    const request = {
      patientNumber,
      sessionId,
      doses,
    }

    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/prescription/session/check', request)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('checkPrescription error', error, {
            patientNumber,
            sessionId,
            doses,
          })
          reject(error)
        })
    })
  },

  confirmPrescriptions(patientNumber: string, sessionId: string, confirmedAt) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/prescription/session/confirm', {
          patientNumber,
          sessionId,
          confirmedAt,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('confirmPrescriptions error', error, {
            patientNumber,
            sessionId,
            confirmedAt,
          })
          reject(error)
        })
    })
  },
  getPrescriptions(patientNumber: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/prescription/list', { params: { patientNumber } })
        .then(response => {
          sanitizeGetPrescriptions(response.data)
          return resolve(response.data)
        })
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getPrescriptions error', error, {
            patientNumber,
          })
          reject(error)
        })
    })
  },
  getInitialization(patientData) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/prescription/initialization', patientData)
        .then(response => resolve(response))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getInitialization error', error, {
            patientData,
          })
          reject(error)
        })
    })
  },
  getCurrentPrimaryDose(patientNumber: string) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/prescription/primary', { params: { patientNumber } })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getCurrentPrimaryDose error', error, {
            patientNumber,
          })
          reject(error)
        })
    })
  },
  deletePrescriptionDose(
    patientNumber: string,
    prescriptionId: string,
    doseId: Number,
    deleteAction: string,
    versionReason: string,
    auditEntries: Array<AuditEntry>,
    currentClientTime: string
  ) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/prescription/delete', {
          patientNumber,
          prescriptionId,
          doseId,
          deleteAction,
          versionReason,
          auditEntries,
          currentClientTime,
        })
        .then(response => resolve(response))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('deletePrescription error', error, {
            patientNumber,
          })
          reject(error)
        })
    })
  },
  getPrescriptionDoseHistory(
    patientNumber: string,
    doseId: string,
    prescriptionId: string
  ) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/prescription/history', {
          params: {
            patientNumber,
            doseId,
            prescriptionId,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getPrescriptionHistory error', error, {
            patientNumber,
            doseId,
            prescriptionId,
          })
          reject(error)
        })
    })
  },
}

export function sanitizeGetPrescriptions(data) {
  if (!data?.items) return

  let lastDosingDay = undefined
  const result = []

  for (let i = data.items.length - 1; i >= 0; i--) {
    const current = data.items[i]
    if (
      current.dose?.dosingDay &&
      current.dose?.dosingDay !== 'not_applicable' &&
      (current.dose?.dosingDay !== lastDosingDay || i === data.items.length - 1)
    ) {
      lastDosingDay = current.dose.dosingDay

      if (lastDosingDay !== 'not_applicable') {
        current.isDosingDayChanged = true
        current.newDosingDay = lastDosingDay
      }
    }

    result.unshift(current)
  }

  data.items = result
}
