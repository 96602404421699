var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.versionCount && _vm.versionCount > 0)?_c('v-dialog',{attrs:{"max-width":800,"data-testid":"prescription-audit-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"audit-activator",style:({ background: _vm.getAuditDialogBtnColor(_vm.versionCount) }),attrs:{"text":"","x-large":""}},on),[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-history")]),_vm._v(" Audit trail ")],1)]}}],null,false,1667121024),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('DialogCard',{on:{"closeDialog":function($event){_vm.showDialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Audit trail for prescription "),_c('small',{staticClass:"ml-2 grey--text"},[_vm._v("(id: "+_vm._s(_vm.prescriptionId)+")")])]},proxy:true}],null,false,2589685087)},[_c('v-data-table',{attrs:{"disable-sort":true,"fixed-header":true,"headers":_vm.getTableHeaders(),"items":_vm.tableData,"item-key":"rowVersionId","loading":_vm.isLoading,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions,
      },"dense":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.state === _vm.loadingState.LOAD_ERRORED)?_c('UnableToLoadData'):_vm._e()]},proxy:true},{key:"loading",fn:function(){return _vm._l((_vm.skeletonCount),function(i){return _c('v-skeleton-loader',{key:`skeleton_${i}`,attrs:{"height":"38","type":"table-row"}})})},proxy:true},{key:"item",fn:function({ item }){return _vm._l((item.doses),function(dose,index){return _c('tr',{key:`${item.rowVersionId}-${dose.id}-${index}`},[_c('td',{staticClass:"relative",class:[
              {
                'no-border-bottom': !_vm.isLastDoseRow(item.doses, index),
              },
              { 'is-deleted': item.isDeleted },
            ]},[_c('DateFormat',{attrs:{"data-testid":"prescribed-at-date","value":dose.doneAt.time,"format":"dd MMM yyyy"}})],1),(_vm.isBolus)?_c('td',{staticClass:"d-block d-sm-table-cell text-right relative",class:[
              {
                'no-border-bottom': !_vm.isLastDoseRow(item.doses, index),
              },
              { 'is-deleted': item.isDeleted },
            ]},[_vm._v(" "+_vm._s(_vm.$t(`time-point.${dose.timepoint}`))+" ")]):_vm._e(),(!_vm.isCarbCount)?_c('td',{staticClass:"d-block d-sm-table-cell text-right relative",class:[
              {
                'no-border-bottom': !_vm.isLastDoseRow(item.doses, index),
              },
              { 'is-deleted': item.isDeleted },
            ]},[_c('span',[_vm._v(_vm._s(dose.doseValue)+" "+_vm._s(dose.dosingDisplayUnit))])]):_vm._e(),(_vm.isCarbCount)?_c('td',{staticClass:"d-block d-sm-table-cell text-right relative",class:[
              {
                'no-border-bottom': !_vm.isLastDoseRow(item.doses, index),
              },
              { 'is-deleted': item.isDeleted },
            ]},[_vm._v(" 1 "+_vm._s(dose.dosingDisplayUnit)+" : "+_vm._s(dose.insulinToCarbRatio)+" g ")]):_vm._e(),(_vm.isCarbCount)?_c('td',{staticClass:"d-block d-sm-table-cell text-right relative",class:[
              {
                'no-border-bottom': !_vm.isLastDoseRow(item.doses, index),
              },
              { 'is-deleted': item.isDeleted },
            ]},[_vm._v(" "+_vm._s(dose.insulinSensitivityFactor)+" "+_vm._s(_vm.bloodGlucoseUnit)+" ")]):_vm._e(),(index === 0)?_c('td',{staticClass:"pt-2 top-aligned",attrs:{"rowspan":item.doses.length}},[_c('small',{attrs:{"data-testid":"item-modified-date-time"}},[_c('DateFormat',{staticClass:"d-block",attrs:{"value":item.modifiedAt.time,"format":"dd-MMM-yyyy","data-testid":"item-modified-date"}}),_c('DateFormat',{attrs:{"value":item.modifiedAt.time,"format":"HH:mm:ss","data-testid":"item-modified-time"}})],1)]):_vm._e(),(index === 0)?_c('td',{staticClass:"pt-2 top-aligned",attrs:{"rowspan":item.doses.length}},[_c('small',[_c('span',{staticClass:"d-block"},[_c('VersionBy',{attrs:{"versionBy":item.versionBy}})],1),_vm._l((item.doses),function(dose,index){return [(dose.reasonForDeviation)?_c('span',{key:index},[_vm._v("Reason for deviation: "+_vm._s(_vm.reasonTypeOptions(dose.reasonForDeviation))),_c('br')]):_vm._e()]}),(item.isDeleted)?_c('span',[_vm._v("Reason for deletion: "),(item.versionReason === 'Transcription error')?_c('span',[_vm._v(" "+_vm._s(item.versionReason)+" ")]):_c('span',[_vm._v(" Other: "),_c('label',{staticClass:"deleted-reason-text"},[_vm._v("\""+_vm._s(item.versionReason)+"\"")])])]):_c('span',[_vm._v(_vm._s(item.versionReason))])],2)]):_vm._e()])})}}],null,false,1807768629)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }