<template>
  <v-dialog
    v-if="versionCount && versionCount > 0"
    v-model="showDialog"
    :max-width="800"
    data-testid="prescription-audit-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        text
        v-on="on"
        :style="{ background: getAuditDialogBtnColor(versionCount) }"
        x-large
        class="audit-activator"
      >
        <v-icon class="pr-2">mdi-history</v-icon>
        Audit trail
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false">
      <template #title>
        Audit trail for prescription
        <small class="ml-2 grey--text">(id: {{ prescriptionId }})</small>
      </template>

      <v-data-table
        :disable-sort="true"
        :fixed-header="true"
        :headers="getTableHeaders()"
        :items="tableData"
        item-key="rowVersionId"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        dense
      >
        <template #no-data>
          <UnableToLoadData v-if="state === loadingState.LOAD_ERRORED" />
        </template>

        <template #loading>
          <v-skeleton-loader
            v-for="i in skeletonCount"
            :key="`skeleton_${i}`"
            height="38"
            type="table-row"
          />
        </template>

        <template #item="{ item }">
          <tr
            v-for="(dose, index) in item.doses"
            :key="`${item.rowVersionId}-${dose.id}-${index}`"
          >
            <td
              class="relative"
              :class="[
                {
                  'no-border-bottom': !isLastDoseRow(item.doses, index),
                },
                { 'is-deleted': item.isDeleted },
              ]"
            >
              <DateFormat
                data-testid="prescribed-at-date"
                :value="dose.doneAt.time"
                format="dd MMM yyyy"
              />
            </td>
            <td
              v-if="isBolus"
              class="d-block d-sm-table-cell text-right relative"
              :class="[
                {
                  'no-border-bottom': !isLastDoseRow(item.doses, index),
                },
                { 'is-deleted': item.isDeleted },
              ]"
            >
              {{ $t(`time-point.${dose.timepoint}`) }}
            </td>
            <td
              v-if="!isCarbCount"
              class="d-block d-sm-table-cell text-right relative"
              :class="[
                {
                  'no-border-bottom': !isLastDoseRow(item.doses, index),
                },
                { 'is-deleted': item.isDeleted },
              ]"
            >
              <span>{{ dose.doseValue }} {{ dose.dosingDisplayUnit }}</span>
            </td>
            <td
              v-if="isCarbCount"
              class="d-block d-sm-table-cell text-right relative"
              :class="[
                {
                  'no-border-bottom': !isLastDoseRow(item.doses, index),
                },
                { 'is-deleted': item.isDeleted },
              ]"
            >
              1 {{ dose.dosingDisplayUnit }} : {{ dose.insulinToCarbRatio }} g
            </td>
            <td
              v-if="isCarbCount"
              class="d-block d-sm-table-cell text-right relative"
              :class="[
                {
                  'no-border-bottom': !isLastDoseRow(item.doses, index),
                },
                { 'is-deleted': item.isDeleted },
              ]"
            >
              {{ dose.insulinSensitivityFactor }}
              {{ bloodGlucoseUnit }}
            </td>
            <td
              class="pt-2 top-aligned"
              v-if="index === 0"
              :rowspan="item.doses.length"
            >
              <small data-testid="item-modified-date-time">
                <DateFormat
                  :value="item.modifiedAt.time"
                  format="dd-MMM-yyyy"
                  data-testid="item-modified-date"
                  class="d-block"
                />
                <DateFormat
                  :value="item.modifiedAt.time"
                  format="HH:mm:ss"
                  data-testid="item-modified-time"
                />
              </small>
            </td>
            <td
              class="pt-2 top-aligned"
              v-if="index === 0"
              :rowspan="item.doses.length"
            >
              <small>
                <span class="d-block">
                  <VersionBy :versionBy="item.versionBy" />
                </span>
                <template v-for="(dose, index) in item.doses">
                  <span :key="index" v-if="dose.reasonForDeviation"
                    >Reason for deviation:
                    {{ reasonTypeOptions(dose.reasonForDeviation) }}<br
                  /></span>
                </template>
                <span v-if="item.isDeleted"
                  >Reason for deletion:
                  <span v-if="item.versionReason === 'Transcription error'">
                    {{ item.versionReason }}
                  </span>
                  <span v-else>
                    Other:
                    <label class="deleted-reason-text"
                      >"{{ item.versionReason }}"</label
                    >
                  </span>
                </span>
                <span v-else>{{ item.versionReason }}</span>
              </small>
            </td>
          </tr>
        </template>
      </v-data-table>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import loadingState from '@/constants/loadingState'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import DialogCard from '@/components/DialogCard.vue'
import auditDialogMixin from '@/components/mixins/auditDialogMixin'
import service from '@/services/prescription-service'
import DateFormat from '@/components/DateFormat'
import VersionBy from '@/components/VersionBy.vue'
import deviationReason from '@/constants/deviationReason'

export default Vue.extend({
  name: 'PrescriptionAuditDialog',
  components: {
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
    DialogCard,
    DateFormat,
    VersionBy,
  },
  mixins: [auditDialogMixin],
  props: {
    doseId: { type: Number, required: true },
    prescriptionId: { type: Number, required: true },
    versionCount: { type: Number, required: true },
    patientNo: { type: String, required: true },
    isCarbCount: { type: Boolean, required: true },
    isBolus: { type: Boolean, required: true },
    bloodGlucoseUnit: { type: String, required: false },
  },
  data() {
    return {
      itemsPerPageOptions,
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    tableData() {
      return this.data || []
    },
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    skeletonCount() {
      return this.versionCount > 10 ? 10 : this.versionCount
    },
  },
  methods: {
    getTableHeaders() {
      let arr = [
        { text: 'Date', value: 'date', sortable: false, width: '115px' },
      ]

      if (this.isBolus) {
        arr.push({
          text: 'Dose type',
          value: 'timepoint',
          sortable: false,
          align: 'end',
          width: '150px',
        })
        if (this.isCarbCount) {
          arr.push({
            text: 'Insulin to carb ratio',
            value: 'insulinCarbRation',
            sortable: false,
            align: 'end',
            width: '95px',
          })

          arr.push({
            text: 'Insulin sensitivity factor',
            value: 'insulinSensitivityFactor',
            sortable: false,
            align: 'end',
            width: '110px',
          })
        } else {
          arr.push({
            text: 'Prescribed dose',
            value: 'prescribedDose',
            sortable: false,
            align: 'right',
          })
        }
      } else {
        arr.push({
          text: 'Prescribed dose',
          value: 'prescribedDose',
          sortable: false,
          align: 'right',
        })
      }

      arr.push(
        { text: 'Modified', value: 'versionAt', width: '100px' },
        { text: 'By / reason', value: 'byReason' }
      )

      return arr
    },

    reasonTypeOptions(reasonForDeviation) {
      if (reasonForDeviation?.deviationType === deviationReason.HYPO) {
        return 'Hypo episode'
      }
      if (
        reasonForDeviation?.deviationType ===
        deviationReason.GASTROINTESTINAL_ADVERSE_EVENT
      ) {
        return 'Gastrointestinal adverse event'
      }
      if (reasonForDeviation?.deviationType === deviationReason.OTHER) {
        return reasonForDeviation.text
      }
    },

    isLastDoseRow(doses, index) {
      return doses.length - 1 === index
    },
    loadData() {
      this.data = null
      this.state = loadingState.LOADING
      service
        .getPrescriptionDoseHistory(
          this.patientNo,
          this.doseId,
          this.prescriptionId
        )
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.audit-activator {
  width: 100%;
  justify-content: left;
}

.top-aligned {
  vertical-align: top;
}

::v-deep.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: #ffffff !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.deleted-reason-text {
  color: $nn-D_T60;
}
</style>
