<template>
  <svg width="15" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 1v16M1 1v3a3 3 0 006 0V1m7 8c-2-.667-3-2-3-4s1-3.333 3-4v16-8z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="square"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLunch',
}
</script>
