
import Vue from 'vue'
import PatientPage from '@/components/patient/PatientPage.vue'
import AddPrescription from '@/components/prescription/add/AddPrescription.vue'
import PrescriptionLogbook from '@/components/prescription/list/PrescriptionLogbook.vue'
import service from '@/services/patient-service'
import prescriptionService from '@/services/prescription-service'
import loadingState from '@/constants/loadingState'
import ConfirmClose from '@/components/ConfirmClose.vue'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'
import DoseTypeInfo from '@/components/DoseTypeInfo.vue'
import prescriptionType from '@/constants/prescriptionType'
import deviceInfo from '@/utils/deviceInfo'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'

export default Vue.extend({
  name: 'Prescription',
  mixins: [
    trialSettingsGettersMixin,
    selectedPatientGettersMixin,
    permissionsGettersMixin,
  ],
  components: {
    NotEnabled: () => import('@/components/layout/NotEnabled.vue'),
    WarningBox: () => import('@/components/WarningBox.vue'),
    ConfirmClose,
    PatientPage,
    AddPrescription,
    PrescriptionLogbook,
    DoseTypeInfo,
  },
  props: {
    patientNo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addPrescriptionActive: false,
      state: loadingState.INITIAL,
      loadTreatmentState: loadingState.INITIAL,
      patient: null,
      treatments: [],
      isStartNewPrescriptionDeviceSupported: false,
    }
  },
  computed: {
    prescriptionsEnabled() {
      return this.trialFeatureFlags(this.patientNo).prescriptionsEnabled
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    isSelectedPatientLoading() {
      return (
        this.selectedPatientConfigurationLoadingState ===
          loadingState.LOADING ||
        this.selectedPatientConfigurationLoadingState === loadingState.INITIAL
      )
    },
    showPrescriptionLogbook() {
      return !this.isSelectedPatientLoading && this.prescriptionsEnabled
    },
    showNotEnabled() {
      return !this.isSelectedPatientLoading && !this.prescriptionsEnabled
    },
    isStartNewPrescriptionWithReason: function () {
      if (!this.isStartNewPrescriptionDeviceSupported) {
        return 'disabledBecauseOfDevice'
      } else {
        switch (this.patient?.currentState) {
          case 'activated':
            return 'enabled'
          case 'in_follow_up':
            return 'disabledBecauseOfFollowUp'
          case 'completed':
            return 'disabledBecauseOfCompleted'
          default:
            return null
        }
      }
    },
  },
  methods: {
    reloadData() {
      this.loadPatient()
      this.$refs.prescriptionLogbook.fetchList()
    },
    loadPatient() {
      this.state = loadingState.LOADING
      this.loadTreatmentState = loadingState.LOADING
      service
        .getPatient(this.patientNo)
        .then((response: any) => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.patient = response.data
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })

      prescriptionService
        .getTreatment(this.patientNo)
        .then((response: any) => {
          this.loadTreatmentState = loadingState.LOAD_SUCCEEDED
          this.treatments = response.items.sort(a =>
            a.type === prescriptionType.BOLUS ||
            a.type === prescriptionType.BOLUS_CARB_COUNT
              ? -1
              : 1
          )
        })
        .catch(error => {
          this.loadTreatmentState = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    this.loadPatient()
    this.isStartNewPrescriptionDeviceSupported = deviceInfo.isIpadAir2019()
  },
  beforeRouteLeave(to, from, next) {
    if (this.addPrescriptionActive) {
      if (this.$refs.addPrescription.guardOnNavigation()) {
        this.$refs.confirmClose.open().then(confirm => {
          if (confirm)
            this.$refs.addPrescription.closeDialog({ forceClose: true })
        })
      } else {
        this.$refs.addPrescription.closeDialog()
      }
      next(false)
    } else {
      next()
    }
  },
})
