<template>
  <PatientHeader :patient-no="patientNo" :app-state="appState">
    <v-row v-if="showHeader" :class="{ 'mb-3': !pageTitle }">
      <v-col sm="4" class="pt-0">
        <div class="mb-2">
          <span class="text-patient-no mr-2">Patient {{ patientNo }}</span>

          <PatientAppState :appState="appState" />
        </div>

        <h4 v-if="pageTitle" class="page-title">{{ pageTitle }}</h4>
      </v-col>
      <v-col sm="8" class="d-flex justify-end pt-0">
        <slot name="right-content" />
      </v-col>
    </v-row>
    <v-row v-if="showHeader">
      <v-col>
        <slot name="sub-header" />
      </v-col>
    </v-row>
    <slot />
  </PatientHeader>
</template>

<script>
import Vue from 'vue'
import PatientHeader from './PatientHeader'
import PatientAppState from './PatientAppStateLabel'
import {
  selectedPatientMapActions,
  selectedPatientMapGetters,
} from '@/store/modules/selectedPatientModule'
import appService from '@/services/app-service'
import patientService from '@/services/patient-service'
import { updatePatientConfigurationAppState } from '@/utils/updateAppState'
import patientConfigurationAppState from '@/constants/patientConfigurationAppState'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'
import appState from '@/constants/appState'

export default {
  name: 'PatientPage',
  props: {
    patientNo: { type: String, required: true },
    pageTitle: { type: String, required: false, default: null },
    showHeader: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      isMounted: false,
    }
  },
  components: {
    PatientHeader,
    PatientAppState,
  },
  computed: {
    ...selectedPatientMapGetters(),
    appState() {
      return this.selectedPatientConfiguration(this.patientNo)?.appState || ''
    },
    patientState() {
      return (
        this.selectedPatientConfiguration(this.patientNo)?.patientState
          ?.currentState || ''
      )
    },
    isPatientCompleted() {
      return this.patientState === patientConfigurationPatientState.COMPLETED
    },
    isAppBlocked() {
      return this.appState === patientConfigurationAppState.BLOCKED
    },
  },
  methods: {
    ...selectedPatientMapActions(),

    pollForAppActivation() {
      if (this.patientNo) {
        appService
          .getAppState(this.patientNo)
          .then(response => {
            if (this.isMounted) {
              if (response.state !== appState.ACTIVATE_RESUMABLE) {
                updatePatientConfigurationAppState({
                  state: response.state,
                  action: this.selectedPatientUpdateAppState,
                  patientNumber: this.patientNo,
                })
              } else {
                setTimeout(() => {
                  if (this.isMounted) {
                    this.pollForAppActivation()
                  }
                }, 3000)
              }
            }
          })
          .catch(error => {
            this.$log.error(error)
            Vue.$tracking.componentError(this, error)
          })
      }
    },

    pollForAppDeactivation() {
      if (this.patientNo) {
        patientService
          .getConfiguration(this.patientNo)
          .then(response => {
            if (this.isMounted) {
              const data = response?.data
              if (data.appState === 'blocked') {
                this.selectedPatientUpdateConfiguration(data)
              } else {
                setTimeout(() => {
                  if (this.isMounted) {
                    this.pollForAppDeactivation()
                  }
                }, 3000)
              }
            }
          })
          .catch(error => {
            this.$log.error(error)
            Vue.$tracking.componentError(this, error)
          })
      }
    },
  },
  watch: {
    appState(newAppState, oldAppState) {
      if (newAppState !== oldAppState) {
        if (newAppState === patientConfigurationAppState.PENDING) {
          this.pollForAppActivation()
        }
      }
    },
    patientState(newPatientState, oldPatientState) {
      if (newPatientState !== oldPatientState) {
        if (
          newPatientState === patientConfigurationPatientState.COMPLETED &&
          !this.isAppBlocked
        ) {
          this.pollForAppDeactivation()
        }
      }
    },
  },
  mounted() {
    this.isMounted = true
    if (this.appState === patientConfigurationAppState.PENDING) {
      this.pollForAppActivation()
    }
    if (!this.isAppBlocked && this.isPatientCompleted) {
      this.pollForAppDeactivation()
    }
  },
  beforeDestroy() {
    this.isMounted = false
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  color: $nn-D_T20;
}
.patient-app-state {
  padding: 2px 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  display: inline-block;
  height: auto;
  min-height: 0;
}
</style>
