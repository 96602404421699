<template>
  <div
    class="d-flex align-center"
    :class="[{ 'is-hypo': measure.isHypoglycemia }]"
  >
    <div
      class="d-flex justify-end align-center"
      :class="{ 'inner-value-wrapper': !noLeftPadding }"
    >
      <IconArrowDown
        v-if="measure.isHypoglycemia"
        :class="[{ 'is-deleted': isDeleted }]"
        class="is-hypo icon-arrow-down"
        data-testid="bgm-arrow-down"
      />
      <span
        :class="[
          { 'is-deleted': isDeleted },
          { 'is-hypo': measure.isHypoglycemia },
        ]"
        data-testid="bgm-display-value"
        >{{ displayValue }}</span
      >
    </div>
    <slot />
  </div>
</template>

<script>
import IconArrowDown from './icons/IconArrowDown'

const isInteger = number => number === parseInt(number, 10)

export default {
  name: 'SmbgValue',
  components: {
    IconArrowDown,
  },
  props: {
    measure: { type: Object, required: true },
    noLeftPadding: { type: Boolean, required: false, default: false },
    isDeleted: { type: Boolean, required: false, default: false },
  },
  computed: {
    displayValue() {
      if (this.measure.isLow) return 'LO'
      if (this.measure.isHigh) return 'HI'

      let displayValue = this.measure.value

      if (this.measure.minimumDecimalsDisplay && isInteger(displayValue)) {
        displayValue = displayValue.toFixed(this.measure.minimumDecimalsDisplay)
      }

      return `${displayValue} ${this.measure.unit}`
    },
  },
}
</script>

<style lang="scss" scoped>
.inner-value-wrapper {
  width: 106px;
}
.smbg-value {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.display-unit {
  margin-left: 8px;
}
.icon-arrow-down {
  margin-right: 4px;
  width: 14px;
  height: 16px;
  position: relative;
  top: -1px;
}
</style>
