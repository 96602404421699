<template>
  <svg width="27" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.28 16.772l.414.28.715-1.054-1.241.287.113.487zM9.72 4.942l.413.28.715-1.054-1.241.287.113.487zm7.448 11.343c-.475.11-.968.167-1.476.167v1c.584 0 1.153-.066 1.701-.193l-.225-.974zm-1.476.167c-3.74 0-6.807-3.167-6.807-7.119h-1c0 4.464 3.476 8.12 7.807 8.12v-1zM8.885 9.333c0-1.533.463-2.95 1.248-4.11l-.827-.561a8.308 8.308 0 00-1.421 4.671h1zM4.5 12.381c0-3.415 2.295-6.25 5.332-6.951l-.225-.975C6.1 5.265 3.5 8.518 3.5 12.381h1zm6.808 7.119c-3.74 0-6.808-3.167-6.808-7.119h-1c0 4.464 3.476 8.119 7.808 8.119v-1zm5.558-3.009c-1.237 1.827-3.27 3.009-5.558 3.009v1c2.646 0 4.977-1.368 6.386-3.448l-.828-.56zM15.692 7.81H12.77v-.635l1.625-2.343h-1.588V4h2.844v.635l-1.624 2.342h1.666v.833zM19.346 10.095h-2.923V9.46l1.625-2.342H16.46v-.832h2.844v.635L17.68 9.263h1.666v.832zM23 7.81h-2.923v-.635l1.624-2.343h-1.587V4h2.844v.635l-1.624 2.342H23v.833z"
      fill="#001965"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconZzz',
}
</script>
