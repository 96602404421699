<template>
  <v-simple-table
    :class="[
      { 'add-context': isAddDoseContext },
      { 'list-context': isListDoseContext },
      'no-border',
    ]"
    data-testid="prescription-data"
  >
    <template #default>
      <tbody>
        <tr>
          <td
            :class="[
              { 'identation-add-context': isAddDoseContext },
              { 'identation-list-context': isListDoseContext },
            ]"
          ></td>
          <td
            colspan="4"
            :class="[
              { 'bg-values-add-context': isAddDoseContext },
              { 'bg-values-list-context': isListDoseContext },
            ]"
          >
            <div class="d-flex align-center">
              <strong class="mr-3">Blood glucose values</strong>
              <SmbgTimePointLabel
                :time-point="firstSmbgTimepoint"
                :small-icon="true"
              />
            </div>
          </td>
        </tr>

        <UnderlyingDataSmbgRow
          v-for="(item, index) in smbgs"
          :source="item"
          :isAddDoseContext="isAddDoseContext"
          :key="`smbg_${index}`"
          :clockNotation="clockNotation"
          :patientNo="patientNo"
        />

        <tr>
          <td colspan="5" class="spacer pt-2">
            <hr v-if="!isAddDoseContext" />
          </td>
        </tr>
        <UnderlyingDataInsulinHeader
          :isAlternativeDose="false"
          :isAddDoseContext="isAddDoseContext"
        />
        <UnderlyingDataInsulinRow
          v-for="(item, index) in expectedInsulins"
          :source="item"
          :index="index"
          :isAddDoseContext="isAddDoseContext"
          :key="`expected_insulin_${index}`"
          :clockNotation="clockNotation"
          :treatmentTimepoint="treatmentTimepoint"
        />
        <UnderlyingDataInsulinRow
          v-for="(derivedItem, derivedIndex) in getDerivedInsulinData"
          :source="derivedItem"
          :isAddDoseContext="isAddDoseContext"
          :key="`insulin_derived_${derivedIndex}`"
          :clockNotation="clockNotation"
          :treatmentTimepoint="getDerivedInsulinTimepoint"
        />
        <template v-if="hasAlternativeInsulins">
          <UnderlyingDataInsulinHeader
            :isAlternativeDose="true"
            :isAddDoseContext="isAddDoseContext"
          />
          <UnderlyingDataInsulinRow
            v-for="(item, index) in alternativeInsulins"
            :source="item"
            :index="index"
            :isAddDoseContext="isAddDoseContext"
            :key="`alternative_insulin_${index}`"
            :clockNotation="clockNotation"
            :treatmentTimepoint="treatmentTimepoint"
          />
        </template>
        <tr v-if="!isAddDoseContext">
          <td colspan="5" class="spacer pt-2"></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import UnderlyingDataInsulinHeader from './_UnderlyingDataInsulinHeader'
import UnderlyingDataInsulinRow from './_UnderlyingDataInsulinRow'
import UnderlyingDataSmbgRow from './_UnderlyingDataSmbgRow'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import SmbgTimePointLabel from '@/components/blood-glucose-measurements/SmbgTimePointLabel'
import underlyingDataContext from '@/constants/underlyingDataContext'

export default {
  name: 'UnderlyingData',
  components: {
    UnderlyingDataInsulinHeader,
    SmbgTimePointLabel,
    UnderlyingDataSmbgRow,
    UnderlyingDataInsulinRow,
  },
  props: {
    smbgs: { type: Array, required: true },
    insulins: { type: Array, required: true },
    underlyingDataContext: { type: String, required: true },
    patientNo: { type: String, required: true },
    treatmentTimepoint: { type: String, required: true },
    derivedDose: { type: Object, required: false },
  },
  computed: {
    ...selectedPatientMapGetters(),
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    firstSmbgTimepoint() {
      return this.smbgs[0].expectedSmbgTimepoint
    },
    getDerivedInsulinData() {
      return this.derivedDose?.underlyingData?.insulins || []
    },
    getDerivedInsulinTimepoint() {
      return this.derivedDose?.treatment.timepoint || ''
    },
    expectedInsulins() {
      return this.insulins.filter(i => !i.isAlternativeDose)
    },
    alternativeInsulins() {
      return this.insulins.filter(i => i.isAlternativeDose)
    },
    hasAlternativeInsulins() {
      return this.alternativeInsulins.length > 0
    },
    isAddDoseContext() {
      return this.underlyingDataContext === underlyingDataContext.ADD_DOSE
    },
    isListDoseContext() {
      return this.underlyingDataContext === underlyingDataContext.LIST_DOSE
    },
  },
}
</script>

<style lang="scss" scoped>
.add-context {
  td {
    background-color: $nn-TB_T98;
  }
}

.add-context.v-data-table {
  ::v-deep {
    tbody {
      td {
        background-color: white;
        height: 42px;
      }
    }
  }
}

.list-context.v-data-table {
  ::v-deep {
    tbody {
      td {
        background-color: $nn-LB_T98;
        padding: 0px !important;
        height: 30px !important;

        &.font-small,
        .font-small {
          font-size: 14px !important;
        }
      }
    }
  }
}

.identation-list-context {
  width: 24px;
}
.identation-add-context {
  width: 36px;
}

.spacer {
  height: auto !important;

  hr {
    border-top: 1px solid $nn-D_T90;
    padding: 0;
  }
}

.bg-values-add-context {
  padding-left: 0px !important;
  font-size: 14px !important;
  padding-top: 32px !important;
  padding-bottom: 16px !important;
  letter-spacing: -0.04em !important;
}

.bg-values-list-context {
  padding: 0px !important;
  font-size: 14px;
  letter-spacing: -0.04em !important;
}
</style>
