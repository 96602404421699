<template>
  <v-chip
    label
    data-testid="confirmed"
    :class="{ 'font-size-14': !smallFont }"
    color="#F1FAF0"
    :small="small"
  >
    <v-icon small class="mr-1" :color="iconColor">mdi-check-circle</v-icon>
    <span :class="{ 'is-deleted': isDeleted }">
      <DateFormat :value="date" />
    </span>
  </v-chip>
</template>

<script>
import DateFormat from './DateFormat'
export default {
  name: 'ConfirmedAt',
  components: { DateFormat },
  props: {
    date: { type: [Object, String, Date], default: null },
    small: { type: Boolean, required: false, default: false },
    smallFont: { type: Boolean, required: false, default: false },
    isDeleted: { type: Boolean, required: false, default: false },
  },
  computed: {
    iconColor() {
      return this.isDeleted ? '#999' : '#2A918B'
    },
  },
}
</script>
