<template>
  <v-alert type="error" text class="mb-0">
    <slot>Unable to load data, please try again.</slot>
  </v-alert>
</template>

<script>
export default {
  name: 'UnableToLoadData',
}
</script>
