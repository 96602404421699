<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="secondary" dark v-bind="attrs" v-on="on" :small="small"
        >mdi-information</v-icon
      >
    </template>
    <span
      >To change the Dosing day for the<br />
      patient, please go to Settings.</span
    >
  </v-tooltip>
</template>

<script>
export default {
  name: 'DosingDayToolTip',
  props: {
    small: { type: Boolean, required: false, default: false },
  },
}
</script>
