import { render, staticRenderFns } from "./UnderlyingData.vue?vue&type=template&id=d7bf5066&scoped=true"
import script from "./UnderlyingData.vue?vue&type=script&lang=js"
export * from "./UnderlyingData.vue?vue&type=script&lang=js"
import style0 from "./UnderlyingData.vue?vue&type=style&index=0&id=d7bf5066&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7bf5066",
  null
  
)

export default component.exports