<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
    data-testid="confirm-close"
  >
    <DialogCard @closeDialog="cancel">
      <template #title>
        <v-icon class="mr-2 icon-information">mdi-alert-circle-outline</v-icon>
        <slot name="title" />
      </template>
      <template #actions>
        <v-btn
          rounded
          depressed
          class="mr-4 elevation-1 btn-cancel"
          color="default"
          @click.native="cancel"
          data-testid="btn-cancel"
          >Cancel</v-btn
        >
        <v-btn
          rounded
          depressed
          class="elevation-1 btn-close"
          color="error"
          @click.native="agree"
          type="submit"
          data-testid="btn-close"
        >
          <slot name="btn-close">Close</slot>
        </v-btn>
      </template>
      <slot />
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from './DialogCard'
/**
 * https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <ConfirmClose ref="confirm"></ConfirmClose>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 */
export default {
  name: 'ConfirmClose',
  components: { DialogCard },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
  }),
  methods: {
    open() {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-information {
  top: -1px;

  &.v-icon::before {
    font-size: 20px;
    color: $nn-lava-red;
  }
}
</style>
