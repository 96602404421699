<template>
  <div v-if="timePoint === 'not_set'" class="timepoint-label">
    <span data-testid="bgm-label" class="spacing-2 timepoint-text pl-2">{{
      notSetText
    }}</span>
  </div>
  <div v-else-if="timePoint === 'before_breakfast'" class="timepoint-label">
    <span :class="getContainerClass" class="icon-blue">
      <IconCoffee :class="getIconClass" />
    </span>
    <span data-testid="bgm-label" class="spacing-2 timepoint-text">{{
      $t('bg-time-point.before_breakfast')
    }}</span>
  </div>
  <div v-else-if="timePoint === 'before_lunch'" class="timepoint-label">
    <span :class="getContainerClass" class="icon-pink">
      <IconLunch :class="getIconClass" />
    </span>
    <span data-testid="bgm-label" class="spacing-2 timepoint-text">{{
      $t('bg-time-point.before_lunch')
    }}</span>
  </div>
  <div v-else-if="timePoint === 'before_dinner'" class="timepoint-label">
    <span :class="getContainerClass" class="icon-green">
      <IconCooking :class="getIconClass" />
    </span>
    <span data-testid="bgm-label" class="spacing-2 timepoint-text">{{
      $t('bg-time-point.before_dinner')
    }}</span>
  </div>
  <div v-else-if="timePoint === 'bedtime'" class="timepoint-label">
    <span :class="getContainerClass" class="icon-true-blue">
      <IconZzz :class="getIconClass" />
    </span>
    <span data-testid="bgm-label" class="spacing-2 timepoint-text">{{
      $t('bg-time-point.bedtime')
    }}</span>
  </div>
  <div v-else-if="timePoint === 'other'" class="timepoint-label">
    <span :class="getContainerClass" class="icon-sand">
      <IconBloodDropThin :class="getIconClass" />
    </span>
    <span data-testid="bgm-label" class="spacing-2 timepoint-text">{{
      $t('bg-time-point.other')
    }}</span>
  </div>
  <div v-else class="timepoint-label">
    <span class="spacer" />
    <span data-testid="bgm-label">—</span>
  </div>
</template>

<script>
import IconCoffee from '@/components/icons/IconCoffee'
import IconBloodDropThin from '@/components/icons/IconBloodDropThin'
import IconZzz from '@/components/icons/IconZzz'
import IconCooking from '@/components/icons/IconCooking'
import IconLunch from '@/components/icons/IconLunch'

export default {
  name: 'SmbgTimePointLabel',
  components: {
    IconCoffee,
    IconBloodDropThin,
    IconZzz,
    IconCooking,
    IconLunch,
  },
  props: {
    timePoint: { type: String, required: true },
    smallIcon: { type: Boolean, required: false, default: false },
    notSetText: { type: String, required: false, default: '—' },
  },
  computed: {
    getContainerClass() {
      return this.smallIcon ? '' : 'icon-container'
    },
    getIconClass() {
      return this.smallIcon ? 'icon-small' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.timepoint-label {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
}
.timepoint-text {
  font-size: 14px;
}
.spacer {
  display: inline-block;
  width: 16px;
  margin-right: 7px;
}
.icon-small {
  transform: scale(0.7);
  margin-right: 4px;
  margin-top: 1px;
}
.icon-container {
  width: 32px;
  height: 32px;
  padding: 4px;
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  margin-right: 7px;
  margin-top: 1px;
}

.icon-blue {
  color: $nn-sea-blue;
}

.icon-sand {
  color: $nn-D_T20;
}

.icon-true-blue {
  color: $nn-true-blue;
}

.icon-green {
  color: $nn-ocean-green;
}

.icon-pink {
  color: $nn-rose-pink;
}
</style>
