<template>
  <svg width="14" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 19c3.314 0 6-2.61 6-6 0-3.333-2-7-6-11-4 4-6 7.667-6 11 0 3.39 2.686 6 6 6z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconBloodDropThin',
}
</script>
