<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.311 5.424c1.054.544 1.653 1.175 1.89 1.776h-14.5c.236-.6.836-1.232 1.89-1.776 1.39-.718 3.318-1.138 5.36-1.138 2.042 0 3.97.42 5.36 1.138zM12.644 4.286h.6v-.6c0-.343-.138-.671-.381-.912a1.297 1.297 0 00-1.824 0c-.243.24-.381.569-.381.912v.6H12.644zM4.87 9.6h14.394V17a3.4 3.4 0 01-3.4 3.4H8.271a3.4 3.4 0 01-3.4-3.4V9.6z"
      stroke="currentColor"
      stroke-width="1.2"
    />
    <path
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      d="M3.999 11.314H2.6M21.4 11.314h-1.399"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCooking',
}
</script>
