export default {
  computed: {
    reasonProvidedMaxLength() {
      return 200
    },
    reasonProvidedRules() {
      const maxAllowed = this.reasonProvidedMaxLength

      return [
        (v: any) => !!v || 'Please provide a reason',
        (v: any) =>
          (v && v.trim().length > 0) || 'Please provide a reason text',
        (v: any) =>
          (v && v.length <= maxAllowed) || `Max ${maxAllowed} characters`,
        (v: any) =>
          (v && /^[a-zA-Z0-9 /.,"'\-–?:!;{}()\][]+$/.test(v)) ||
          'Only use A-Z, 0-9 and English punctuation marks',
      ]
    },
  },
  methods: {
    hasReasonProvidedErrors(v) {
      let result = false
      this.reasonProvidedRules.forEach(fun => {
        if (fun(v) !== true) {
          result = true
        }
      })

      return result
    },
  },
}
