<template>
  <div>
    <v-tabs
      v-if="showTabs"
      class="mt-3"
      v-model="tab"
      background-color="#f7f9fc"
      active-class="active-tab"
    >
      <v-tabs-slider color="#005ad2" style="width: 46%; margin-left: 27%" />
      <template v-for="type in prescriptionTypes">
        <v-tab
          :key="type"
          @click="applyFilter(type)"
          :class="{ 'obsolete-dose-type': !isValidPrescriptionType(type) }"
          >{{ filterTabLabel(type) }}</v-tab
        >
      </template>
    </v-tabs>
    <v-card class="rounded-bottom-lg" data-testid="prescription-log-book">
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        :expanded.sync="expanded"
        :custom-sort="customSort"
        group-by="prescriptionId"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        item-key="id"
        class="elevation-1 prescription-table spacing-2"
      >
        <template #top="{ pagination, options, updateOptions }">
          <div class="d-flex justify-center align-center">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              :items-per-page-options="itemsPerPageOptions"
              class="top-footer-item ml-auto"
            >
            </v-data-footer>
          </div>
        </template>

        <template #group.header>
          <td
            :colspan="headersLength"
            class="group-header"
            style="height: 0 !important"
          ></td>
        </template>

        <template #group.summary="{ items }">
          <td
            v-if="isBolusTypePrescription(items[0].type)"
            :colspan="headersLength - 1"
            class="group-summary small-text-row always-white px-4"
          >
            Prescribed by:
            <strong>{{ items[0].prescribedBy.name }}</strong>
            <strong v-if="items[0].prescribedBy.role"
              >, {{ rolesText(items[0].prescribedBy.role) }}</strong
            >

            <div
              v-if="hasDosingDayChanged(items[0])"
              class="d-inline-block ml-3"
            >
              Dosing day set to:
              <v-chip x-small label class="dose-type-value spacing-2">
                <span class="text-capitalize"
                  ><strong>{{ items[0].dose.dosingDay }}</strong></span
                ></v-chip
              >
            </div>
          </td>
          <td
            v-if="isBolusTypePrescription(items[0].type)"
            class="text-right group-summary always-white"
          >
            <FurtherActions
              :dose="items[0].dose"
              :prescription-id="items[0].prescriptionId"
              :patient-no="patientNo"
              :is-deleted="items[0].isDeleted"
              :version-count="items[0].versionCount"
              :is-carb-count="isCarbCountTypePrescription(items[0].type)"
              :is-bolus="isBolusTypePrescription(items[0].type)"
              :blood-glucose-unit="items[0].bloodGlucoseUnit"
              :isInitial="isInitialPrescription(items[0])"
              @prescription-edited="$emit('prescription-edited')"
            />
          </td>
        </template>
        <template #item="{ item, isExpanded, expand }">
          <tr
            class="always-white"
            :class="[
              {
                'no-border-bottom': hasExtraInformation(item),
              },
              { 'no-border-bottom': isExpanded || !hasUnderlyingData(item) },
            ]"
          >
            <td :class="{ 'is-deleted': item.isDeleted }" class="relative">
              <span>{{ $t(`time-point.${item.dose.timepoint}`) }}</span>
            </td>
            <td
              class="text-large relative"
              :class="{ 'is-deleted': item.isDeleted }"
            >
              <DateFormat
                data-testid="prescribed-at"
                :value="item.prescribedAt.time"
              />
            </td>
            <td class="relative">
              <ConfirmedAt
                v-if="item.confirmedAt"
                :small="true"
                :date="item.confirmedAt.time"
                :isDeleted="item.isDeleted"
              />
              <Unconfirmed v-else :small="true" :isDeleted="item.isDeleted" />
            </td>
            <td
              v-if="!isCarbCountTypePrescription(item.type)"
              class="d-block d-sm-table-cell text-right relative"
              :class="{ 'is-deleted': item.isDeleted }"
            >
              <span v-if="item.dose"
                >{{ item.dose.doseValue }}
                {{ item.dose.dosingDisplayUnit }}</span
              >
              <span v-else class="mr-12">-</span>
            </td>
            <td
              v-if="isCarbCountTypePrescription(item.type)"
              class="d-block d-sm-table-cell text-right relative"
              :class="{ 'is-deleted': item.isDeleted }"
            >
              1 {{ item.dose.dosingDisplayUnit }} :
              {{ item.dose.insulinToCarbRatio }} g
            </td>
            <td
              v-if="isCarbCountTypePrescription(item.type)"
              class="d-block d-sm-table-cell text-right relative"
              :class="{ 'is-deleted': item.isDeleted }"
            >
              {{ item.dose.insulinSensitivityFactor }}
              {{ item.bloodGlucoseUnit }}
            </td>
            <td
              v-if="!isBolusTypePrescription(item.type)"
              class="text-right pr-0"
            >
              <FurtherActions
                :dose="item.dose"
                :prescription-id="item.prescriptionId"
                :patient-no="patientNo"
                :is-deleted="item.isDeleted"
                :version-count="item.versionCount"
                :is-carb-count="isCarbCountTypePrescription(item.type)"
                :is-bolus="isBolusTypePrescription(item.type)"
                :blood-glucose-unit="item.bloodGlucoseUnit"
                :isInitial="isInitialPrescription(item)"
                @prescription-edited="$emit('prescription-edited')"
              />
            </td>
            <td
              v-if="!isBolusTypePrescription(item.type)"
              class="text-right pl-0"
            >
              <div
                v-if="isInitialPrescription(item)"
                class="d-inline-flex justify-center"
              >
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icon-info"
                      >mdi-information</v-icon
                    >
                  </template>
                  <span>Initial dose prescription</span>
                </v-tooltip>
              </div>
              <v-btn v-else-if="!isExpanded" @click="expand(true)" icon text>
                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
              </v-btn>
              <v-btn v-else @click="expand(false)" color="secondary" icon text>
                <v-icon>mdi-arrow-up-drop-circle</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="item.derivedDose">
            <td class="relative" :class="{ 'is-deleted': item.isDeleted }">
              {{ $t(`time-point.${item.derivedDose.timepoint}`) }}
            </td>
            <td class="relative">
              <DateFormat
                data-testid="prescribed-at"
                :value="item.prescribedAt.time"
                :class="{ 'is-deleted': item.isDeleted }"
              />
            </td>
            <td class="relative">
              <ConfirmedAt
                v-if="item.confirmedAt"
                :small="true"
                :date="item.confirmedAt.time"
                :isDeleted="item.isDeleted"
              />
              <Unconfirmed v-else :small="true" :isDeleted="item.isDeleted" />
            </td>
            <td
              class="d-block d-sm-table-cell text-right relative"
              :class="{ 'is-deleted': item.isDeleted }"
            >
              <span v-if="item.derivedDose"
                >{{ item.derivedDose.doseValue }}
                {{ item.derivedDose.dosingDisplayUnit }}</span
              >
              <span v-else class="mr-12">-</span>
            </td>

            <td></td>
          </tr>

          <tr
            v-if="!isBolusTypePrescription(item.type)"
            class="small-text-row always-white"
            :class="{
              'no-border-bottom':
                isExpanded ||
                hasDeviationReason(item) ||
                !hasUnderlyingData(item),
            }"
          >
            <td :colspan="headersLength">
              Prescribed by:
              <strong>{{ item.prescribedBy.name }}</strong>
              <strong v-if="item.prescribedBy.role"
                >, {{ rolesText(item.prescribedBy.role) }}</strong
              >

              <div v-if="hasDosingDayChanged(item)" class="d-inline-block ml-3">
                Dosing day set to:
                <v-chip x-small label class="dose-type-value spacing-2">
                  <span class="text-capitalize"
                    ><strong>{{ item.dose.dosingDay }}</strong></span
                  ></v-chip
                >
              </div>
            </td>
          </tr>
          <tr
            v-if="hasDeviationReason(item)"
            class="small-text-row always-white"
            :class="{
              'no-border-bottom': isExpanded || !hasUnderlyingData(item),
            }"
          >
            <td :colspan="headersLength - 1">
              Reason for deviation:
              <strong v-if="isHypoglycemia(item)">Hypo episode.</strong>
              <strong v-else-if="isGastrointestinalAdverseEvent(item)"
                >Gastrointestinal Adverse Event.</strong
              >
              <strong v-else>Other</strong>
            </td>
            <td></td>
          </tr>
          <tr
            v-if="
              isExpanded &&
              hasDeviationReason(item) &&
              !isHypoglycemia(item) &&
              !isGastrointestinalAdverseEvent(item)
            "
            class="small-text-row always-white spacing-2"
          >
            <td :colspan="headersLength">
              <i class="d-block">{{ item.dose.reasonForDeviation.text }}</i>
            </td>
          </tr>
        </template>
        <template #no-data>
          <template v-if="isLoading">Loading... Please wait </template>
          <UnableToLoadData v-if="loadingState === loadingState.LOAD_ERRORED" />
        </template>
        <template #expanded-item="{ item }">
          <tr>
            <td :colspan="headersLength" class="pa-0 always-border-bottom">
              <PrescriptionDetail
                :prescription="item"
                :patient-no="patientNo"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import service from '@/services/prescription-service'
import loadingState from '@/constants/loadingState'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import DateFormat from '@/components/DateFormat.vue'
import PrescriptionDetail from './_PrescriptionDetail.vue'
import FurtherActions from './_FurtherActions'
import ConfirmedAt from '@/components/ConfirmedAt'
import Unconfirmed from '@/components/Unconfirmed'
import { rolesText } from '@/constants/userRole'
import UnableToLoadData from '@/components/UnableToLoadData'

import prescriptionType from '@/constants/prescriptionType'
import globalProgressActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'
import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import dosingRegimen from '@/constants/dosingRegimen'
import deviationReason from '@/constants/deviationReason'

export default Vue.extend({
  name: 'PrescriptionLogbook',
  mixins: [
    globalProgressActionsMixins,
    selectedPatientGettersMixin,
    trialSettingsGettersMixin,
  ],
  components: {
    UnableToLoadData,
    ConfirmedAt,
    Unconfirmed,
    PrescriptionDetail,
    DateFormat,
    FurtherActions,
  },
  props: {
    patientNo: { type: String, required: true },
    patient: { type: Object, required: false },
    landscape: { type: Boolean, required: true },
  },
  data() {
    return {
      itemsPerPageOptions,
      loadingState: loadingState.INITIAL,
      prescriptions: [],
      expanded: [],
      tab: null,
      prescriptionTypes: [],
      currentFilter: '',
      prescriptionType,
    }
  },
  computed: {
    isLoading() {
      return this.loadingState === loadingState.LOADING
    },
    showTabs() {
      return this.prescriptionTypes.length > 1
    },
    filteredItems() {
      if (this.prescriptions.length === 0) return []
      if (this.prescriptionTypes.length < 2) return this.prescriptions

      return this.prescriptions.filter(x => x.type === this.currentFilter)
    },
    headersLength() {
      return this.headers.length
    },
    headers() {
      var arr = [
        {
          text: 'Dose type',
          width: this.landscape ? '190px' : '140px',
          sortable: false,
        },
        {
          text: 'Date',
          value: 'prescribedAt',
          width: '150px',
        },
        {
          text: 'Received by patient on',
          value: 'confirmedAt',
        },
      ]

      if (this.currentFilter === this.prescriptionType.BOLUS_CARB_COUNT) {
        arr.push({
          text: this.landscape ? 'Insulin to carb ratio' : 'I:CHO',
          value: 'dose.doseValue',
          sortable: false,
          align: 'end',
        })

        arr.push({
          text: this.landscape ? 'Insulin sensitivity factor' : 'ISF',
          value: 'dose.doseValue',
          sortable: false,
          align: 'end',
        })
      } else {
        arr.push({
          text: 'Prescribed dose',
          value: 'dose.doseValue',
          sortable: false,
          align: 'end',
        })
      }

      if (this.currentFilter !== this.prescriptionType.BOLUS_CARB_COUNT) {
        arr.push({
          text: '',
          value: 'furtherActions',
          width: '60px',
          sortable: false,
        })
      }

      if (
        this.currentFilter !== this.prescriptionType.BOLUS_CARB_COUNT &&
        this.currentFilter !== this.prescriptionType.BOLUS
      ) {
        arr.push({
          text: '',
          value: 'data-table-expand',
          width: '60px',
          sortable: false,
        })
      }

      return arr
    },
  },
  methods: {
    isHypoglycemia(item) {
      return item.dose.reasonForDeviation.deviationType === deviationReason.HYPO
    },
    isGastrointestinalAdverseEvent(item) {
      return (
        item.dose.reasonForDeviation.deviationType ===
        deviationReason.GASTROINTESTINAL_ADVERSE_EVENT
      )
    },
    isValidPrescriptionType(type) {
      const currentTreatmentArmId = this.selectedPatientTreatmentArmId(
        this.patientNo
      )
      const currentlyValidTreatments = this.trialTreatmentArms.find(
        x => x.id === currentTreatmentArmId
      )?.treatments

      const isValidPrescription = currentlyValidTreatments.some(
        x => x.type === type
      )

      const isMultipleRegimenPresent = currentlyValidTreatments.some(
        x => x.dosingRegimens?.length > 1
      )

      // If there is only one regimen on all of the patient's treatments,
      // the comparison of isValidPrescription can be used
      if (!isValidPrescription && !isMultipleRegimenPresent) return false

      // If there is multiple regimen on any of the patient's treatments,
      // compare the patient.dosigRegimen with the type of prescription
      if (type === prescriptionType.BOLUS_CARB_COUNT) {
        return this.patient?.dosingRegimen === dosingRegimen.CARB_COUNT
      }
      if (type === prescriptionType.BOLUS) {
        return this.patient?.dosingRegimen === dosingRegimen.TITRATION
      }

      // Rest of the prescription type are all titration based
      return isValidPrescription
    },
    isCarbCountTypePrescription(type) {
      return type === this.prescriptionType.BOLUS_CARB_COUNT
    },
    isBolusTypePrescription(type) {
      return (
        type === this.prescriptionType.BOLUS ||
        type === this.prescriptionType.BOLUS_CARB_COUNT
      )
    },
    filterTabLabel(type) {
      if (type === this.prescriptionType.BASAL_ONCE_WEEKLY)
        return 'Weekly basal dose'
      if (type === this.prescriptionType.BASAL_ONCE_DAILY)
        return 'Daily basal dose'
      if (type === this.prescriptionType.BOLUS) return 'Bolus doses'
      if (type === this.prescriptionType.BOLUS_CARB_COUNT)
        return 'Carb counting factors'
      return type
    },
    applyFilter(type) {
      this.currentFilter = type
    },
    hasUnderlyingData(item) {
      return item?.dose.underlyingData
    },
    isInitialPrescription(item) {
      return item?.isInitial
    },
    hasExtraInformation(item) {
      return item && (item.isDosingDayChanged || item.dose.reasonForDeviation)
    },
    hasDosingDayChanged(item) {
      return item && item.isDosingDayChanged
    },
    hasDeviationReason(item) {
      return item && item.dose?.reasonForDeviation
    },
    fetchList() {
      this.loadingState = loadingState.LOADING
      this.globalStartLoadingRequested()

      service
        .getPrescriptions(this.patientNo)
        .then(response => {
          this.loadingState = loadingState.LOAD_SUCCEEDED
          this.prescriptions = response.items
          this.prescriptionTypes = response.metadata?.prescriptionTypes
            .sort(a => (a.startsWith('bolus') ? 1 : -1))
            .sort((a, b) => {
              if (
                this.isValidPrescriptionType(a) &&
                this.isValidPrescriptionType(b)
              )
                return 0
              if (
                !this.isValidPrescriptionType(a) &&
                !this.isValidPrescriptionType(b)
              )
                return 0
              return this.isValidPrescriptionType(a) ? -1 : 1
            })
          if (!this.currentFilter)
            this.currentFilter = this.prescriptionTypes[0]
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
    customSort: function (items, index, isDesc) {
      // index[0] is prescriptionId, used for grouping the table rows
      // and by using group-by, it is automatically present as the first element of index
      items.sort((a, b) => sort(index[1], a[index[1]], b[index[1]], isDesc[1]))
      return items
    },
    rolesText(roles) {
      return rolesText(roles)
    },
  },
  mounted() {
    this.fetchList()
  },
})

function sort(index, a, b, isDesc) {
  if (!a && !b) return 0

  if (typeof a === 'number') {
    if (a === b) return 0

    if (isDesc) {
      if (a > b) return 1
      if (b > a) return -1
    } else {
      if (a > b) return -1
      if (b > a) return 1
    }

    return 0
  } else if (index === 'prescribedAt' || index === 'confirmedAt') {
    if (!a?.time && !b?.time) return 0
    if (a?.time && !b?.time) return isDesc ? -1 : 1
    if (b?.time && !a?.time) return isDesc ? 1 : -1

    if (a?.time && b?.time) {
      const first = isDesc ? new Date(a.time) : new Date(b.time)
      const second = isDesc ? new Date(b.time) : new Date(a.time)
      if (first > second) return 1
      if (second > first) return -1

      return 0
    }
  } else {
    return isDesc ? localeCompare(a, b) : localeCompare(b, a)
  }

  return 0
}

function localeCompare(item1, item2) {
  return (item1 + '').toLowerCase().localeCompare((item2 + '').toLowerCase())
}
</script>

<style lang="scss" scoped>
.active-tab {
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.v-tab {
  text-transform: none !important;
  color: $nn-sea-blue !important;
  font-weight: bold;
}

.obsolete-dose-type {
  color: $nn-D_T50 !important;
}

.v-data-table {
  .top-footer-item {
    border-top: none;
  }
  ::v-deep thead th {
    background-color: $nn-TB_T98;
    letter-spacing: -0.02em;
    color: $nn-D_T20 !important;
    height: 40px !important;
    border-bottom: none !important;
    > span {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }
}

.prescription-card-title {
  color: $nn-granite-gray;
  min-width: 152px;
}

.icon-info {
  width: 40px;
  height: 40px;
}

.group-summary {
  background-color: white !important;
  height: auto !important;
  padding-bottom: 10px !important;
  font-size: 12px !important;
}

.prescription-table.v-data-table {
  tbody {
    tr {
      &.always-white {
        background-color: white !important;
        position: relative;
      }

      &.no-border-bottom {
        td {
          border-bottom: none !important;
        }
      }

      &.small-text-row {
        td {
          height: auto !important;
          padding-bottom: 8px;
          padding-top: 0;
          font-size: 12px;
        }
      }
    }

    td {
      border-bottom: none !important;
      height: 48px;

      &.small-text {
        font-size: 12px;
      }
      &.blue-text {
        color: $nn-LB_T20;
      }

      &.always-border-bottom {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
      }

      &.group-header {
        background-color: white;
        height: 0 !important;
        border-bottom: none !important;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
}
</style>
