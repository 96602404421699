<template>
  <v-chip
    v-if="appState"
    label
    :text-color="patientAppState.textColor"
    :color="patientAppState.color"
    class="patient-app-state"
  >
    {{ patientAppState.text }}
  </v-chip>
</template>

<script>
import appStateMixin from '@/components/patient/appStateMixin'

export default {
  name: 'PatientAppState',
  mixins: [appStateMixin],
}
</script>

<style lang="scss" scoped>
.patient-app-state {
  padding: 2px 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  display: inline-block;
  height: auto;
  min-height: 0;
}
</style>
