<template>
  <v-row>
    <v-col md="12" lg="8" xl="6" offset-lg="2" offset-xl="3"
      ><v-row>
        <v-col sm="auto" class="info-chip pb-0">
          <div class="chip-header">Patient ID</div>
          <span class="chip-text">{{ patientNumber }}</span>
        </v-col>
        <v-col sm="auto" class="info-chip pb-0">
          <div class="chip-header">Last BG value import</div>
          <DateFormat
            class="chip-text"
            :value="patientLastSyncedAt"
            :format="dateFormatString"
          />
        </v-col>
        <v-col v-if="dosingDay" sm="auto" class="info-chip pb-0">
          <div class="chip-header">Preferred dosing day</div>
          <div class="chip-text text-capitalize d-flex align-center">
            <div class="mr-2">{{ dosingDay }}</div>
            <DosingDayToolTip small />
          </div>
        </v-col>
        <v-col class="text-right pb-0">
          <v-btn
            rounded
            @click="$emit('refresh')"
            :loading="isLoading"
            class="elevation-1"
          >
            Refresh data
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DateFormat from '@/components/DateFormat.vue'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import DosingDayToolTip from '../shared/DosingDayToolTip'

export default {
  name: 'PatientInfoHeader',
  components: {
    DosingDayToolTip,
    DateFormat,
  },
  props: {
    sessionResponse: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    ...selectedPatientMapGetters(),
    dateFormatString() {
      return `dd MMM yyyy, ${hourFormatter(
        this.selectedPatientClockNotation(this.patientNo)
      )}`
    },
    patientNumber() {
      return this.sessionResponse?.patientNumber
    },
    patientLastSyncedAt() {
      return this.sessionResponse?.bgmLastSyncedAt?.time
    },
    dosingDay() {
      return this.sessionResponse?.dosingDay
    },
  },
}
</script>

<style scoped lang="scss">
.info-chip {
  color: $nn-D_T10;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;

  .icon-container {
    background: $nn-LB-T80;
    width: 22px;
    height: 22px;
    padding: 3px;
    display: inline-block;
    border-radius: 4px;
    text-align: center;
    margin-right: 6px;
    line-height: 1;
  }

  .icon {
    color: $nn-light-blue;
    width: 12px;
    height: 12px;
  }
  .chip-header {
    color: $nn-D_T20;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
  }
  .chip-text {
    color: $nn-D_T20;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
