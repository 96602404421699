<template>
  <div>
    <v-btn
      rounded
      @click="startNewTitration"
      :loading="isLoading"
      data-testid="start-new-prescription"
      class="button-start"
      :class="{ 'start-new-primary': !isSecondaryPrescription }"
      elevation="1"
      >{{ btnStartNewPrescriptionLabel }}</v-btn
    >

    <v-dialog
      v-model="showDialog"
      fullscreen
      :persistent="true"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <BoxedToolbar
        @close-dialog="closeDialog"
        data-testid="add-prescription-card-title"
      >
        New dose prescription for patient {{ patientNo }}
      </BoxedToolbar>

      <v-card color="#f7f9fC">
        <v-card-text>
          <PatientInfoHeader
            :sessionResponse="apiResponse"
            :isLoading="isLoading"
            @refresh="refreshData"
          />

          <v-row>
            <v-col
              md="12"
              lg="10"
              xl="8"
              offset-lg="1"
              offset-xl="2"
              class="pt-1"
            >
              <OnceWeeklyRecurringDose
                v-if="isOnceWeekly"
                :session-response="apiResponse"
                ref="prescriptionDoseCard"
                @done="previewPrescriptions()"
              />
              <OnceDailyRecurringDose
                v-else-if="isOnceDaily"
                :session-response="apiResponse"
                ref="prescriptionDoseCard"
                @done="previewPrescriptions()"
              />
              <DoubleDummyRecurringDose
                v-else-if="isDoubleDummy"
                :session-response="apiResponse"
                ref="prescriptionDoseCard"
                @done="previewPrescriptions()"
              />
              <BolusRecurringDose
                v-else-if="isBolus"
                :session-response="apiResponse"
                ref="prescriptionDoseCard"
                @done="previewPrescriptions()"
              />
              <BolusCarbCountRecurringDose
                v-else-if="isBolusCarbCount"
                :session-response="apiResponse"
                ref="prescriptionDoseCard"
                @done="previewPrescriptions()"
              />
              <div class="bottom-screen"></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDoseNotExpectedDialog" max-width="541">
      <DialogCard @closeDialog="showDoseNotExpectedDialog = false">
        <template #title> New prescription has already been made </template>
        <template #actions>
          <v-btn
            rounded
            depressed
            :loading="isLoading"
            @click="loadRecommendation"
            class="mr-2"
            data-testid="btn-prescription-unexpected-continue"
            >Continue</v-btn
          >
          <v-btn
            rounded
            depressed
            :loading="isLoading"
            color="secondary"
            @click="showDoseNotExpectedDialog = false"
            data-testid="btn-prescription-unexpected-cancel"
            >Cancel</v-btn
          >
        </template>
        <p class="mb-8">
          Last prescription sent on:
          <strong>
            <DateFormat
              v-if="showDoseNotExpectedDialog"
              :value="prescriptionAllowedResponse.lastPrescriptionDoneAt.time"
          /></strong>
        </p>

        <v-alert type="error" dense text class="pa-4">
          <p class="text--black">
            Be aware a
            <strong>prescription has already been sent</strong>
            to the patient within the last
            {{ prescriptionAllowedResponse.expectedMinFrequencyDays }}
            {{
              $tc('days', prescriptionAllowedResponse.expectedMinFrequencyDays)
            }}.
          </p>
          <p class="text--black">
            A minimum of
            {{ prescriptionAllowedResponse.expectedMinFrequencyDays }}
            {{
              $tc('days', prescriptionAllowedResponse.expectedMinFrequencyDays)
            }}
            between doses taken should always be ensured.
          </p>
        </v-alert>
      </DialogCard>
    </v-dialog>

    <v-dialog v-model="showDoseNotAllowedDialog" max-width="541">
      <DialogCard @closeDialog="showDoseNotAllowedDialog = false">
        <template #title> New prescription is not allowed </template>
        <template #actions>
          <v-btn
            rounded
            depressed
            color="secondary"
            @click="showDoseNotAllowedDialog = false"
            data-testid="delete-prescription-warning-cancel"
            >Close</v-btn
          >
        </template>
        <v-alert type="error" dense text class="pa-4">
          <strong class="text--black"
            >Only one active prescription is allowed per day</strong
          >

          <p class="mt-5 mb-2 text--black">
            If a new prescription is needed for today, the active prescription
            must be deleted first.
          </p>
        </v-alert>
      </DialogCard>
    </v-dialog>

    <v-dialog
      v-model="preview.showDialog"
      persistent
      :max-width="isBolusCarbCount ? 620 : 542"
    >
      <DialogCard @closeDialog="preview.showDialog = false">
        <template #title>
          <h5>
            Confirm the following for patient
            {{ preview.response.patientNumber }}
          </h5>
        </template>
        <template #actions>
          <v-btn
            rounded
            depressed
            class="mr-4 elevation-1 btn-cancel"
            color="default"
            @click="preview.showDialog = false"
            >Edit dose</v-btn
          >

          <v-btn
            color="secondary"
            rounded
            depressed
            class="elevation-1 btn-close"
            @click="confirmPrescriptions"
            :loading="isConfirmLoading"
            >Confirm &amp; send</v-btn
          >
        </template>

        <ConfirmAdjustments
          v-if="isBolusCarbCount"
          :preview-response="preview.response"
          :api-response="apiResponse"
        />
        <ConfirmDoseList v-else :doses="preview.response.doses" />
      </DialogCard>
    </v-dialog>

    <ConfirmClose ref="confirmClose">
      <template #title>Closing prescription</template>
      <template #btn-close>Close prescription</template>
      <p class="spacing-2">
        Closing the new prescription at this point means that
        <strong>all the information you have filled in will be lost.</strong>
      </p>

      <p>Do you want to close?</p>
    </ConfirmClose>
  </div>
</template>

<script>
import Vue from 'vue'

import ConfirmClose from '@/components/ConfirmClose.vue'
import DialogCard from '@/components/DialogCard.vue'
import PatientInfoHeader from './_PatientInfoHeader.vue'
import DateFormat from '@/components/DateFormat'

import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import { notificationMapActions } from '@/store/modules/notificationModule'
import NotificationType from '@/store/modules/notification/NotificationType'

import service from '@/services/prescription-service'
import loadingState from '@/constants/loadingState'
import timepointKeys from '@/constants/timepointKeys'
import prescriptionType from '@/constants/prescriptionType'

const initialPreview = () => ({
  loadingState: loadingState.INITIAL,
  showDialog: false,
  response: { doses: [] },
})

export default Vue.extend({
  name: 'AddPrescription',
  components: {
    DateFormat,
    OnceDailyRecurringDose: () => import('./_OnceDailyRecurringDose'),
    OnceWeeklyRecurringDose: () => import('./_OnceWeeklyRecurringDose.vue'),
    DoubleDummyRecurringDose: () => import('./_DoubleDummyRecurringDose.vue'),
    ConfirmAdjustments: () => import('./ConfirmAdjustments'),
    BolusCarbCountRecurringDose: () => import('./_BolusCarbCountRecurringDose'),
    ConfirmDoseList: () => import('./ConfirmDoseList'),
    BolusRecurringDose: () => import('./_BolusRecurringDose'),
    BoxedToolbar: () => import('@/components/layout/BoxedToolbar'),
    PatientInfoHeader,
    DialogCard,
    ConfirmClose,
  },
  props: {
    patientNo: { type: String, required: true },
    patient: { type: [Object, null], required: false },
    treatment: { type: [Object, null], required: true },
  },
  data() {
    return {
      showDialog: false,
      showDoseNotAllowedDialog: false,
      showDoseNotExpectedDialog: false,
      apiResponse: {},
      prescriptions: [],
      state: loadingState.INITIAL,
      preview: initialPreview(),
      prescriptionAllowedResponse: {},
    }
  },
  computed: {
    isBolus() {
      return this.treatment.type === prescriptionType.BOLUS
    },
    isBolusCarbCount() {
      return this.treatment.type === prescriptionType.BOLUS_CARB_COUNT
    },
    isSecondaryPrescription() {
      return (
        this.treatment.type === prescriptionType.BOLUS ||
        this.treatment.type === prescriptionType.BOLUS_CARB_COUNT
      )
    },
    isDoubleDummy() {
      return this.treatment.type === prescriptionType.DOUBLE_DUMMY
    },
    isOnceWeekly() {
      return this.treatment.type === prescriptionType.BASAL_ONCE_WEEKLY
    },
    isOnceDaily() {
      return this.treatment.type === prescriptionType.BASAL_ONCE_DAILY
    },
    isConfirmLoading() {
      return (
        this.preview.loadingState === loadingState.LOADING || this.isLoading
      )
    },
    btnStartNewPrescriptionLabel() {
      switch (this.treatment.type) {
        case prescriptionType.BOLUS:
          return 'Prescribe bolus dose'
        case prescriptionType.BOLUS_CARB_COUNT:
          return 'Adjust carb counting factors'
        case prescriptionType.BASAL_ONCE_WEEKLY:
          return 'Prescribe weekly dose'
        case prescriptionType.BASAL_ONCE_DAILY:
          return 'Prescribe daily dose'
        default:
          return 'New prescription'
      }
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    ...notificationMapActions(),
    startNewTitration() {
      this.$emit('toggle', true)
      this.state = loadingState.LOADING
      this.preview = initialPreview()

      service
        .isNewPrescriptionAllowed(
          this.patientNo,
          dateTimeWithTimeZone(),
          this.treatment.id,
          this.treatment.type
        )
        .then(response => {
          if (response.status === 'prescription_too_frequent') {
            this.$emit('toggle', false)
            this.showDoseNotAllowedDialog = true
            this.state = loadingState.LOAD_ERRORED
            return
          } else if (response.status === 'prescription_not_expected') {
            this.$emit('toggle', false)
            this.showDoseNotExpectedDialog = true
            this.prescriptionAllowedResponse = response
            this.state = loadingState.LOAD_ERRORED
            return
          }

          this.loadRecommendation()
        })
        .catch(error => {
          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Prescription could not be started, please try again.',
          })
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    loadRecommendation() {
      this.state = loadingState.LOADING
      service
        .startNewPrescription(
          this.patientNo,
          dateTimeWithTimeZone(),
          this.treatment.id,
          this.treatment.type
        )
        .then(response => {
          this.showDoseNotExpectedDialog = false
          this.apiResponse = response
          this.prescriptions = response.doses
          this.showDialog = true
          this.state = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Prescription could not be started, please try again.',
          })
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    refreshData() {
      if (!this.isBolusCarbCount && !this.isBolus) {
        this.reloadRecommendation()
      }
      this.$refs.prescriptionDoseCard.refreshData()
    },
    reloadRecommendation() {
      this.state = loadingState.LOAD_SUCCEEDED
      service
        .startNewPrescription(
          this.patientNo,
          dateTimeWithTimeZone(),
          this.treatment.id,
          this.treatment.type
        )
        .then(response => {
          this.$refs.prescriptionDoseCard.resetForm()
          this.apiResponse = response
          this.prescriptions = response.doses
          this.state = loadingState.LOAD_SUCCEEDED
          this.spawnNotification({
            type: NotificationType.Success,
            title: 'Data was refreshed',
          })
        })
        .catch(error => {
          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Data could not be refreshed, please try again.',
          })
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    confirmPrescriptions() {
      this.preview.loadingState = loadingState.LOADING

      service
        .confirmPrescriptions(
          this.patientNo,
          this.apiResponse.sessionId,
          dateTimeWithTimeZone()
        )
        .then(result => {
          this.preview.showDialog = false

          if (result.isOk) {
            this.$emit('new-prescription-added')
            this.closeDialog({ forceClose: true })
          } else this.state = loadingState.LOAD_ERRORED
        })
        .catch(error => {
          this.preview.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)

          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Prescription could not be made, please try again.',
          })
        })
    },
    previewPrescriptions() {
      service
        .previewPrescriptions(this.patientNo, this.apiResponse.sessionId)
        .then(result => {
          // If trial is double dummy, the confirmation window should show weekly dose first
          if (this.isDoubleDummy) {
            result.doses.sort(a =>
              a.timepoint === timepointKeys.basal_once_weekly ? -1 : 1
            )
          }
          this.preview.response = result
          this.preview.showDialog = true
        })
        .catch(error => {
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    closeDialog({ forceClose } = {}) {
      if (forceClose) {
        this.showDialog = false
        this.showDoseNotAllowedDialog = false
        this.showDoseNotExpectedDialog = false
        this.$emit('toggle', false)
        this.$refs.prescriptionDoseCard.resetForm()
      } else if (this.guardOnNavigation()) {
        this.$refs.confirmClose.open().then(confirm => {
          if (confirm) {
            this.showDialog = false
            this.$emit('toggle', false)
            this.$refs.prescriptionDoseCard.resetForm()
          }
        })
      } else {
        this.showDialog = false
        this.$emit('toggle', false)
      }
    },
    guardOnNavigation() {
      return this.$refs.prescriptionDoseCard.isDirty()
    },
    confirmNavigation(event) {
      if (this.guardOnNavigation()) {
        const confirmationMessage = 'Changes you made may not be saved'
        event.returnValue = confirmationMessage // Gecko, Trident, Chrome 34+
        return confirmationMessage
      } else {
        return null
      }
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.confirmNavigation)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.confirmNavigation)
  },
})
</script>

<style lang="scss" scoped>
.button-start {
  // Overriding Vuetify
  height: 48px !important;
  border-radius: 24px;
  font-size: 16px;
  line-height: 1;
  padding: 0 24px !important;
  font-weight: 500;
  letter-spacing: -0.005em;

  &.start-new-primary {
    background-color: $nn-sea-blue !important;
    color: $nn-white;
  }
}
.text--black {
  color: black;
}

.bottom-screen {
  padding-top: 100px;
}
</style>
