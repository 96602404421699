<template>
  <v-menu bottom left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        class="lighten-5 elevation-1"
        v-bind="attrs"
        v-on="on"
        :color="getAuditDialogBtnColor(versionCount)"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item>
        <PrescriptionAuditDialog
          v-if="true"
          :patient-no="patientNo"
          :dose-id="dose.id"
          :prescription-id="prescriptionId"
          :version-count="versionCount"
          :is-carb-count="isCarbCount"
          :is-bolus="isBolus"
          :blood-glucose-unit="bloodGlucoseUnit"
        />
      </v-list-item>
      <v-list-item v-if="isDeletePrescriptionAllowed">
        <DeletePrescription
          :patient-no="patientNo"
          :dose="dose"
          :prescription-id="prescriptionId"
          @prescription-edited="$emit('prescription-edited')"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import PrescriptionAuditDialog from './_PrescriptionAuditDialog'
import DeletePrescription from './_DeletePrescription'
import auditDialogMixin from '@/components/mixins/auditDialogMixin'
import deviceInfo from '@/utils/deviceInfo'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'

export default {
  name: 'FurtherActions',
  components: { PrescriptionAuditDialog, DeletePrescription },
  mixins: [auditDialogMixin, permissionsGettersMixin],
  props: {
    dose: { type: Object, required: true },
    prescriptionId: { type: Number, required: true },
    patientNo: { type: String, required: true },
    isDeleted: { type: Boolean, required: true },
    versionCount: { type: Number, required: true },
    isCarbCount: { type: Boolean, required: true },
    isBolus: { type: Boolean, required: true },
    bloodGlucoseUnit: { type: String, required: false },
    isInitial: { type: Boolean, required: true },
  },
  computed: {
    isDeletePrescriptionAllowed() {
      return (
        this.isUserAllowedToManagePatient &&
        deviceInfo.isIpadAir2019() &&
        !this.isDeleted &&
        !this.isInitial
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 0px !important;
}
.v-list {
  padding: 0px !important;
}
</style>
