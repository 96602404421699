<template>
  <v-col v-if="prescription" class="pa-0">
    <div class="background-strong">
      <div class="pa-4 pl-6 underlying-data-headline" v-if="isDoubleDummy">
        Underlying data for weekly prescription
      </div>

      <UnderlyingData
        v-if="prescription.dose.underlyingData"
        :insulins="prescription.dose.underlyingData.insulins"
        :smbgs="prescription.dose.underlyingData.smbgs"
        :underlying-data-context="underlyingDataContext.LIST_DOSE"
        :patient-no="patientNo"
        :treatment-timepoint="prescription.dose.timepoint"
      />
    </div>
  </v-col>
</template>

<script>
import UnderlyingData from '../shared/UnderlyingData.vue'
import underlyingDataContext from '@/constants/underlyingDataContext'

export default {
  name: 'PrescriptionDetail',
  components: { UnderlyingData },
  props: {
    prescription: { type: Object, required: true },
    patientNo: { type: String, required: true },
  },
  data() {
    return {
      underlyingDataContext,
    }
  },
  computed: {
    isDoubleDummy() {
      return this.prescription.derivedDose && this.prescription.dose
    },
  },
}
</script>

<style lang="scss" scoped>
.background-strong {
  background-color: $nn-LB_T98;
}
.font-md {
  font-size: 20px;
  font-weight: 500;
}
.summery {
  font-size: 16px;
  .input-spacer {
    display: inline-block;
    padding-left: 12px;
    width: 100px;
    text-align: left;
  }
}
.underlying-data-headline {
  color: $nn-light-blue;
}
</style>
