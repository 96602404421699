<template>
  <tr :data-testid="testId" :class="{ 'add-dose-context': isAddDoseContext }">
    <td></td>
    <td class="width-190-px" :data-testid="`${testId}-label`">
      {{ label }}
    </td>
    <td :data-testid="`${testId}-date`" class="width-175-px">
      <DateFormat :value="source.date" />
    </td>
    <td v-if="hasValue" :data-testid="`${testId}-smbg-time`">
      <DateFormat
        :value="source.measurement.doneAt.time"
        :format="hourFormat"
      />
    </td>
    <td v-if="hasValue" :data-testid="`${testId}-smbg-value`">
      <HypoFormDialog
        v-if="source.measurement.hypo"
        :patient-no="patientNo"
        :hypo-id="source.measurement.hypo.hypoId"
      >
        <span class="link-span" data-testid="bgm-hypo-cta">
          <SmbgValue :measure="source.measurement">
            <v-icon small class="ml-1 is-hypo">mdi-open-in-new</v-icon>
          </SmbgValue>
        </span>
      </HypoFormDialog>

      <SmbgValue
        v-else
        :measure="source.measurement"
        :class="[
          { 'right-align': !isAddDoseContext },
          { 'extra-padding': !$vuetify.breakpoint.smAndDown },
        ]"
      />
    </td>
    <td
      v-else-if="source.measurementState === 'pending'"
      colspan="3"
      class="text-center"
      :data-testid="`${testId}-smbg-value`"
    >
      <v-chip label small color="#EBF2FB" text-color="#333" class="full-width">
        Pending
      </v-chip>
    </td>
    <td
      v-else
      colspan="2"
      class="text-center"
      :data-testid="`${testId}-smbg-value`"
    >
      <v-chip label small color="#FDF7E6" text-color="#333" class="full-width">
        No data
      </v-chip>
    </td>
  </tr>
</template>

<script>
import DateFormat from '@/components/DateFormat'
import hourFormatter from '@/utils/date/hourFormatter'
import SmbgValue from '@/components/SmbgValue'

export default {
  name: 'UnderlyingDataSmbgRow',
  props: {
    patientNo: { type: String, required: true },
    source: { type: Object, required: true },
    isAddDoseContext: { type: Boolean, required: true },
    clockNotation: { type: String, required: true },
  },
  components: {
    DateFormat,
    SmbgValue,
    HypoFormDialog: () => import('@/components/hypo-form/HypoFormDialog.vue'),
  },
  computed: {
    testId() {
      if (this.source.dayOffset === 0) return 'day-of-contact'
      if (this.source.dayOffset === 1) return '1-day-prior'

      return `${this.source.dayOffset}-days-prior`
    },
    hourFormat() {
      return hourFormatter(this.clockNotation)
    },
    label() {
      if (this.source.dayOffset === 0) return 'Day of contact'
      if (this.source.dayOffset === 1) return '1 day prior'

      return `${this.source.dayOffset} days prior`
    },
    hasValue() {
      return !!this.source?.measurement
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
  line-height: 15px;
  height: 42px !important;
}

.full-width {
  margin: 0px 16px;
  width: calc(100% - 2 * 16px);
  align-items: center;
  justify-content: center;
}

.add-dose-context {
  td {
    background-color: $nn-TB_T98;
  }
}
.link-span {
  cursor: pointer;
  display: inline-block;
}
.right-align {
  float: right;
  padding-right: 101px;
}
.extra-padding {
  padding-right: 132px;
}
</style>
