<template>
  <v-dialog
    v-model="showDialog"
    max-width="527"
    persistent
    data-testid="delete-prescription-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        text
        x-large
        v-on="on"
        class="delete-activator"
        data-testid="delete-prescription-dialog-open"
      >
        <v-icon class="pr-2 delete">mdi-delete-outline</v-icon>
        Delete
      </v-btn>
    </template>

    <DialogCard
      v-if="ShowConfirmStepOne"
      @closeDialog="closeDeleteDialog"
      data-testid="delete-prescription-warning"
    >
      <template #title
        >Deleting prescription for patient {{ patientNo }}
      </template>
      <template #actions>
        <v-btn
          rounded
          depressed
          class="elevation-1 mr-4"
          @click="closeDeleteDialog"
          data-testid="delete-prescription-warning-cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          rounded
          depressed
          @click="ShowConfirmStepOne = false"
          color="secondary"
          :loading="isLoading"
          data-testid="delete-prescription-warning-continue"
        >
          Continue
        </v-btn>
      </template>
      <div class="mb-4">
        <WarningBox
          isWarningDangerBox
          data-testid="delete-prescription-warning"
        >
          <strong
            >Be aware the patient may already have taken the prescribed
            dose</strong
          >
          <br />
          Make sure the patient is made aware of changes to prescribed doses
        </WarningBox>
      </div>
    </DialogCard>

    <DialogCard v-else @closeDialog="closeDeleteDialog">
      <template #title>Reason for deletion</template>
      <template #actions>
        <v-btn
          rounded
          depressed
          class="elevation-1 mr-4"
          @click="closeDeleteDialog"
          data-testid="delete-prescription-cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          rounded
          depressed
          color="secondary"
          @click="deletePrescription"
          :loading="isLoading"
          :disabled="!isFormValid"
          data-testid="delete-prescription-confirm"
        >
          Confirm
        </v-btn>
      </template>

      <div class="meta-block highlight-area pa-4 mb-4">
        The dose prescription will be <strong>marked as deleted</strong> and
        removed from the patient app on the next sync
        <div class="highlight-text py-2">
          Please provide a reason for <strong>deleting</strong>
        </div>
        <v-form v-model="isFormValid" ref="deletePrescriptionForm">
          <v-row no-gutters>
            <v-col class="col-8">
              <v-select
                outlined
                placeholder="Choose reason"
                class="mt-4 rounded-lg"
                v-model="reason"
                :items="deleteReasonOptions"
                :rules="deleteReasonRules"
                :hide-details="reason === 'Other'"
                background-color="white"
                @change="
                  addAuditEntry('DELETE_DOSE:REASON_FOR_DELETING', $event)
                "
              >
              </v-select>
            </v-col>
          </v-row>
          <v-slide-y-reverse-transition :hide-on-leave="true">
            <v-row v-if="reason === 'Other'" no-gutters class="">
              <v-col class="col-12">
                <v-textarea
                  counter
                  outlined
                  class="mt-2 reason-text rounded-lg spacing-2"
                  rows="4"
                  :auto-grow="true"
                  v-model="reasonProvided"
                  :rules="reasonProvidedRules"
                  :maxlength="reasonProvidedMaxLength"
                  background-color="white"
                  @change="
                    addAuditEntry('DELETE_DOSE:REASON_FOR_DELETING', $event)
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-slide-y-reverse-transition>
        </v-form>
      </div>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import DialogCard from '@/components/DialogCard'
import loadingState from '@/constants/loadingState'
import reasonOtherValidationMixin from '@/components/mixins/reasonOtherValidationMixin'
import service from '@/services/prescription-service'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import WarningBox from '@/components/WarningBox.vue'
import AuditLog from '@/utils/audit/AuditLog'

export default {
  name: 'DeletePrescription',
  mixins: [reasonOtherValidationMixin],
  components: { DialogCard, WarningBox },
  props: {
    dose: { type: Object, required: true },
    prescriptionId: { type: Number, required: true },
    patientNo: { type: String, required: true },
  },
  data() {
    return {
      auditLog: new AuditLog(),
      state: loadingState.INITIAL,
      reason: null,
      reasonProvided: null,
      isFormValid: false,
      showDialog: false,
      ShowConfirmStepOne: true,
      deleteReasonRules: [v => !!v || 'A reason is required'],
      deleteReasonOptions: ['Transcription error', 'Other'],
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    deleteAction() {
      return 'delete'
    },
  },
  methods: {
    closeDeleteDialog() {
      this.showDialog = false
      this.ShowConfirmStepOne = true
      if (this.$refs.deletePrescriptionForm !== undefined)
        this.$refs.deletePrescriptionForm.reset()
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    deletePrescription() {
      this.state = loadingState.LOADING
      const versionReason =
        this.reason === 'Other' ? this.reasonProvided : this.reason

      service
        .deletePrescriptionDose(
          this.patientNo,
          this.prescriptionId,
          this.dose.id,
          this.deleteAction,
          versionReason,
          this.auditLog.getAuditEntries(),
          dateTimeWithTimeZone()
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED

          this.closeDeleteDialog()
          this.$emit('prescription-edited')
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED

          this.closeConfirmDialog()

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-activator {
  color: $nn-lava-red;
  width: 100%;
  justify-content: left;
}

.highlight-area {
  background-color: $nn-SB_T98;
  border-radius: 8px;
}

.highlight-text {
  color: $nn-sea-blue;
}
</style>
