
import Vue from 'vue'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import loadingState from '@/constants/loadingState'
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue'

export default Vue.extend({
  name: 'PatientHeader',
  props: { patientNo: { type: String, required: true } },
  components: { Breadcrumbs },

  computed: {
    ...selectedPatientMapGetters(),
    isLoading() {
      return (
        this.selectedPatientConfigurationLoadingState === loadingState.LOADING
      )
    },
    isLoadErrored() {
      return (
        this.selectedPatientConfigurationLoadingState ===
        loadingState.LOAD_ERRORED
      )
    },
    isLoadSucceeded() {
      return (
        this.selectedPatientConfigurationLoadingState ===
        loadingState.LOAD_SUCCEEDED
      )
    },
  },

  methods: {
    reloadBrowser() {
      location.reload()
    },
  },
})
