import Vue from 'vue'

export default Vue.extend({
  props: {
    appState: { type: String, required: true },
  },
  computed: {
    patientAppState() {
      switch (this.appState) {
        case 'completed':
          return {
            text: 'Active app',
            textShort: 'Active',
            textColor: '#333',
            color: '#e3f5e1',
            dotColor: '#2a918b',
          }
        case 'pending':
          return {
            text: 'Pending app',
            textShort: 'Pending',
            textColor: '#333',
            color: '#F7DD99',
            dotColor: '#EAAB00',
          }
        case 'blocked':
          return {
            text: 'Inactive app',
            textShort: 'Inactive',
            textColor: '#333',
            color: '#F8CCC5',
            dotColor: '#E6553F',
          }

        default:
          return {}
      }
    },
  },
})
