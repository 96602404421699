<template>
  <svg width="18" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 15v-5H1v5a5 5 0 005 5h2a5 5 0 005-5zm0 0h2a2 2 0 100-4h-2M5 7s-1-.5-1-2 1-2 1-2m3-2s1 .5 1 2-1 2-1 2"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="square"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCoffee',
}
</script>
