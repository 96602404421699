const timepointKeys = {
  basal_once_daily: 'basal_once_daily',
  basal_once_weekly: 'basal_once_weekly',
  bolus_breakfast: 'bolus_breakfast',
  bolus_lunch: 'bolus_lunch',
  bolus_dinner: 'bolus_dinner',
  bolus_extra_prescription: 'bolus_extra_prescription',
}
Object.freeze(timepointKeys)

export default timepointKeys
