<template>
  <tr
    :data-testid="`${testId}-row`"
    :class="[{ 'add-dose-context': isAddDoseContext }]"
  >
    <td></td>
    <td class="pl-0">
      <span class="timepoint-label">
        {{ timepointLabel }}
      </span>
    </td>
    <td :data-testid="`${testId}-date`">
      <DateFormat :value="dateValue" />
    </td>
    <td v-if="hasDose" :data-testid="`${testId}-time`">
      <DateFormat :value="timeValue" :format="hourFormat" />
    </td>
    <template v-if="hasDose">
      <td :data-testid="`${testId}-value`">
        <div
          :class="[
            { 'right-align': !isAddDoseContext },
            { 'extra-padding': !$vuetify.breakpoint.smAndDown },
          ]"
        >
          {{ displayValue }}
          <span v-if="isUnitLabelVisible" class="unit-label text-left">{{
            source.dose.dosingDisplayUnit
          }}</span>
        </div>
      </td>
    </template>
    <template v-else>
      <td colspan="2" class="text-center" :data-testid="`${testId}-value`">
        <v-chip
          label
          small
          color="#FDF7E6"
          text-color="#333"
          class="full-width"
        >
          Not reported
        </v-chip>
      </td>
    </template>
  </tr>
</template>

<script>
import DateFormat from '@/components/DateFormat'
import hourFormatter from '@/utils/date/hourFormatter'

export default {
  name: 'UnderlyingDataInsulinRow',
  props: {
    source: { type: Object, required: true },
    index: { type: Number, required: false },
    isAddDoseContext: { type: Boolean, required: true },
    clockNotation: { type: String, required: true },
    treatmentTimepoint: { type: String, required: true },
  },
  components: {
    DateFormat,
  },
  computed: {
    testId() {
      if (this.index) return `insulin-${this.index}`
      return 'insulin_derived_dose'
    },
    timepointLabel() {
      if (this.treatmentTimepoint === 'basal_once_weekly') {
        return 'Weekly dose'
      }
      if (this.treatmentTimepoint === 'basal_once_daily') {
        return 'Daily dose'
      }
      return ''
    },
    hourFormat() {
      return hourFormatter(this.clockNotation)
    },
    label() {
      if (this.source.isAlternativeDose) return 'Alternative dose taken'

      return (
        'Previous ' +
        this.$t(`time-point.${this.treatmentTimepoint}`).toLowerCase() +
        ' taken'
      )
    },
    hasDose() {
      return this.source.dose
    },
    dateValue() {
      if (this.source.dose?.doneAt) return this.source.dose.doneAt.time

      return this.source.date
    },
    timeValue() {
      if (!this.source.dose.isDoneAtTimeSpecified) return null

      return this.source.dose.doneAt.time
    },
    displayValue() {
      if (this.source.dose.status === 'missed') return 'Missed'
      if (this.source.dose.status === 'dont_know') return "Don't remember"

      return this.source.dose.dosedUnits
    },
    isUnitLabelVisible() {
      return this.source.dose?.dosedUnits
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
  line-height: 15px;
  height: 42px !important;
}
.unit-label {
  margin-left: 8px;
}
.full-width {
  margin: 0 16px;
  width: calc(100% - 2 * 16px);
  align-items: center;
  justify-content: center;
}
.add-dose-context {
  td {
    background-color: $nn-SG_T95 !important;
  }
}
.right-align {
  text-align: right;
  padding-right: 101px;
}
.timepoint-label {
  font-size: 14px;
}
.extra-padding {
  padding-right: 132px;
}
</style>
