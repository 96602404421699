var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-simple-table',{class:[
    { 'add-context': _vm.isAddDoseContext },
    { 'list-context': _vm.isListDoseContext },
    'no-border',
  ],attrs:{"data-testid":"prescription-data"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{class:[
            { 'identation-add-context': _vm.isAddDoseContext },
            { 'identation-list-context': _vm.isListDoseContext },
          ]}),_c('td',{class:[
            { 'bg-values-add-context': _vm.isAddDoseContext },
            { 'bg-values-list-context': _vm.isListDoseContext },
          ],attrs:{"colspan":"4"}},[_c('div',{staticClass:"d-flex align-center"},[_c('strong',{staticClass:"mr-3"},[_vm._v("Blood glucose values")]),_c('SmbgTimePointLabel',{attrs:{"time-point":_vm.firstSmbgTimepoint,"small-icon":true}})],1)])]),_vm._l((_vm.smbgs),function(item,index){return _c('UnderlyingDataSmbgRow',{key:`smbg_${index}`,attrs:{"source":item,"isAddDoseContext":_vm.isAddDoseContext,"clockNotation":_vm.clockNotation,"patientNo":_vm.patientNo}})}),_c('tr',[_c('td',{staticClass:"spacer pt-2",attrs:{"colspan":"5"}},[(!_vm.isAddDoseContext)?_c('hr'):_vm._e()])]),_c('UnderlyingDataInsulinHeader',{attrs:{"isAlternativeDose":false,"isAddDoseContext":_vm.isAddDoseContext}}),_vm._l((_vm.expectedInsulins),function(item,index){return _c('UnderlyingDataInsulinRow',{key:`expected_insulin_${index}`,attrs:{"source":item,"index":index,"isAddDoseContext":_vm.isAddDoseContext,"clockNotation":_vm.clockNotation,"treatmentTimepoint":_vm.treatmentTimepoint}})}),_vm._l((_vm.getDerivedInsulinData),function(derivedItem,derivedIndex){return _c('UnderlyingDataInsulinRow',{key:`insulin_derived_${derivedIndex}`,attrs:{"source":derivedItem,"isAddDoseContext":_vm.isAddDoseContext,"clockNotation":_vm.clockNotation,"treatmentTimepoint":_vm.getDerivedInsulinTimepoint}})}),(_vm.hasAlternativeInsulins)?[_c('UnderlyingDataInsulinHeader',{attrs:{"isAlternativeDose":true,"isAddDoseContext":_vm.isAddDoseContext}}),_vm._l((_vm.alternativeInsulins),function(item,index){return _c('UnderlyingDataInsulinRow',{key:`alternative_insulin_${index}`,attrs:{"source":item,"index":index,"isAddDoseContext":_vm.isAddDoseContext,"clockNotation":_vm.clockNotation,"treatmentTimepoint":_vm.treatmentTimepoint}})})]:_vm._e(),(!_vm.isAddDoseContext)?_c('tr',[_c('td',{staticClass:"spacer pt-2",attrs:{"colspan":"5"}})]):_vm._e()],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }