<template>
  <div class="dose-type d-flex align-center mt-2" v-if="showDoseTypeInfo">
    <span class="dose-type-label spacing-2"
      >Dose type{{ doseTypes.length > 1 ? 's' : '' }}</span
    >
    <v-skeleton-loader
      v-if="isLoading"
      type="text"
      width="55"
      :loading="true"
    />

    <v-chip
      v-else-if="isDoubleDummy"
      small
      label
      class="dose-type-value spacing-2"
      ><strong>Weekly &amp; Daily</strong></v-chip
    >

    <v-chip
      v-else
      v-for="dose in doseTypes"
      :key="dose.type"
      small
      label
      class="dose-type-value spacing-2"
      ><strong>{{ $t('dose-type.' + dose.type) }}</strong></v-chip
    >

    <template v-if="dosingDay">
      <span class="dose-type-label spacing-2">Dosing day</span>
      <v-skeleton-loader
        v-if="isLoading"
        type="text"
        width="55"
        :loading="true"
      />
      <v-chip v-else small label class="dose-type-value spacing-2">
        <span class="text-capitalize"
          ><strong>{{ dosingDay }}</strong></span
        ></v-chip
      >
      <DosingDayToolTip />
    </template>

    <template v-if="showDosingRegimens">
      <span class="dose-type-label spacing-2 ml-4">Bolus regimen</span>

      <v-chip small label class="dose-type-value spacing-2"
        ><strong>{{
          $t(`dosing-regimen.${patient.dosingRegimen}`)
        }}</strong></v-chip
      >
    </template>
  </div>
</template>

<script>
import DosingDayToolTip from '@/components/prescription/shared/DosingDayToolTip.vue'
import trialSettingsGettersMixin from './mixins/store/trialSettingsGettersMixin'

export default {
  name: 'DoseTypeInfo',
  mixins: [trialSettingsGettersMixin],
  components: {
    DosingDayToolTip,
  },
  props: {
    patient: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null,
    },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    doseTypes() {
      return this.patient?.insulinTypes || []
    },
    dosingDay() {
      return this.patient?.dosingDay
    },
    isDoubleDummy() {
      return (
        this.doseTypes.some(e => e.type === 'basal_once_daily') &&
        this.doseTypes.some(e => e.type === 'basal_once_weekly')
      )
    },
    showDoseTypeInfo() {
      return this.doseTypes.length !== 0
    },
  },
}
</script>

<style lang="scss" scoped>
.dose-type {
  font-size: 14px;
  line-height: 20px;
}
.dose-type-label {
  display: inline-block;
  padding: 8px 8px 8px 0;
}
.dose-type-value {
  margin-right: 8px;
  background: $nn-TB_T95;
  font-weight: 500;
  padding: 2px 6px;
}
</style>
