<template>
  <v-chip
    label
    data-testid="unconfirmed"
    class="font-size-14 text-no-wrap"
    color="#FDF7E6"
    :small="small"
  >
    <v-icon small class="mr-1" :color="iconColor">
      mdi-alert-circle-outline
    </v-icon>
    <span :class="{ 'is-deleted': isDeleted }"> Unconfirmed </span>
  </v-chip>
</template>

<script>
export default {
  name: 'Unconfirmed',
  props: {
    small: { type: Boolean, required: false, default: false },
    isDeleted: { type: Boolean, required: false, default: false },
  },
  computed: {
    iconColor() {
      return this.isDeleted ? '#999' : 'inherit'
    },
  },
}
</script>
