var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PatientPage',{attrs:{"patient-no":_vm.patientNo,"page-title":"Dose prescription"},scopedSlots:_vm._u([(_vm.showPrescriptionLogbook)?{key:"right-content",fn:function(){return [(_vm.isUserAllowedToManagePatient)?[(_vm.isStartNewPrescriptionWithReason === 'enabled')?_vm._l((_vm.treatments),function(treatment,index){return _c('AddPrescription',{key:`treatment_${index}`,ref:"addPrescription",refInFor:true,staticClass:"ml-2",attrs:{"patient-no":_vm.patientNo,"patient":_vm.patient,"treatment":treatment},on:{"new-prescription-added":_vm.reloadData,"toggle":function($event){_vm.addPrescriptionActive = $event}}})}):(
          _vm.isStartNewPrescriptionWithReason === 'disabledBecauseOfDevice'
        )?_c('WarningBox',{attrs:{"data-testid":"start-new-prescription-unavailable"}},[_c('strong',[_vm._v("Remember:")]),_vm._v(" You can only make a prescription from the iPad provided by Novo Nordisk. ")]):(
          _vm.isStartNewPrescriptionWithReason === 'disabledBecauseOfFollowUp'
        )?_c('WarningBox',{attrs:{"data-testid":"start-new-prescription-unavailable"}},[_c('strong',[_vm._v("Remember:")]),_vm._v(" You cannot prescribe a dose for patients in follow-up. ")]):(
          _vm.isStartNewPrescriptionWithReason === 'disabledBecauseOfCompleted'
        )?_c('WarningBox',{attrs:{"data-testid":"start-new-prescription-unavailable"}},[_c('strong',[_vm._v("Remember:")]),_vm._v(" the patient has ended the trial. ")]):_vm._e()]:_vm._e()]},proxy:true}:null,(_vm.showPrescriptionLogbook)?{key:"sub-header",fn:function(){return [_c('DoseTypeInfo',{attrs:{"patient":_vm.patient,"isLoading":_vm.isLoading}})]},proxy:true}:null],null,true)},[(_vm.isSelectedPatientLoading)?_c('div',{staticClass:"py-10 text-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"grey","indeterminate":""}})],1):_vm._e(),(_vm.showPrescriptionLogbook)?_c('PrescriptionLogbook',{ref:"prescriptionLogbook",attrs:{"patient-no":_vm.patientNo,"patient":_vm.patient,"landscape":!_vm.$vuetify.breakpoint.smAndDown},on:{"prescription-edited":_vm.reloadData}}):_vm._e(),(_vm.showNotEnabled)?_c('NotEnabled',{attrs:{"patient-no":_vm.patientNo}}):_vm._e(),_c('ConfirmClose',{ref:"confirmClose",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Closing prescription")]},proxy:true},{key:"btn-close",fn:function(){return [_vm._v("Close prescription")]},proxy:true}])},[_c('p',{staticClass:"spacing-2"},[_vm._v(" Closing the new prescription at this point means that "),_c('strong',[_vm._v("all the information you have filled in will be lost.")])]),_c('p',[_vm._v("Do you want to close?")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }