<template>
  <tr
    data-testid="insulin-heading-row"
    :class="[{ 'add-dose-context': isAddDoseContext }]"
  >
    <td></td>
    <td
      colspan="4"
      :class="[
        { 'pt-6': isAddDoseContext },
        { 'border-top': isAlternativeDose },
      ]"
      class="pl-0 px-0 font-small spacing-4"
    >
      <strong>{{ label }}</strong>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'UnderlyingDataInsulinHeader',
  props: {
    isAlternativeDose: { type: Boolean, required: true },
    isAddDoseContext: { type: Boolean, required: true },
  },
  computed: {
    label() {
      if (this.isAlternativeDose) return 'Alternative dose taken'

      return 'Previous dose taken'
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
  line-height: 15px;
  height: 48px !important;
}
.add-dose-context {
  td {
    background-color: $nn-SG_T95 !important;
  }

  hr {
    width: 95%;
    margin-bottom: 24px;
    border: 1px solid #e6e6e6;
  }

  &.border-top {
    border-top: 1pt solid $nn-D_T90;
    margin-top: 12px;
  }
}
</style>
